<template>
  <b-card no-body class="card-statistics">
    <b-card-body> 
      <b-row>
        <b-col
          v-for="item in statisticsItemsData"
          :key="item.subtitle"
          class="mb-0 mb-md-0"
          :class="item.customClass"
        >
          <div class="text-center mb-1">
            <b-avatar size="48" :variant="item.color">
              <span
                v-if="item.icon == 'currency-rupee'"
                style="font-size: 22px;"
              >
                ₹</span
              >
              <span v-else><feather-icon size="24" :icon="item.icon"/></span>
            </b-avatar>
          </div>
          <b-media class="text-center" no-body>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody
} from "bootstrap-vue";
import projectsMap from "@/@core/utils/projectDetailMap";
import commaNumber from "comma-number";
import millify from "millify";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody
  },
  props: {
    iconType: {
      type: String
    },
    businessType:{
      type:Array
    },
    summaryData: {
      type: Object,
      default: () => {
        return {
          clicks,
          conversionValue,
          // conversions,
          // costPerClick,
          impressions,
          // roas,
          // sales,
          spends,
          roas,
          cvr,
          cpcl
        };
      }
    }
  },
computed: {
  statisticsItemsData() {
        let statisticsItems = [];
let summaryData = this.summaryData;
// let order = [ 'spends', 'sales','orders','impressions', 'clicks'];
const order = ["costMicros", "spends", "impressions", "clicks", "conversions", "cpl","cpcl", "ctr", "ROAS","cpc", "purchases", "roas", "conversion value","cpm", "cvr"];

for (let i = 0; i < order.length; i++) {
  const dataKey = order[i];
  let title = dataKey;
  title = title.replace("_", " ").toLowerCase();
  if (summaryData[dataKey] !== undefined) {
    let dataToPush = this.getFormattedText(summaryData[dataKey], title);
    if (dataToPush) {
      statisticsItems.push(dataToPush);
    }
  }
}

return statisticsItems;
      },

//   statisticsItemsData() {
//   let statisticsItems = [];
//   let summaryData = this.summaryData;
//   let order = ["spends", "impressions", "clicks", "ctr", "cvr", "roas","conversions"];
//   for (let i = 0; i < order.length; i++) {
//     const dataKey = order[i];
//     let title = dataKey;
//     title = title.replace("_", " ").toLowerCase();
//     if (summaryData[dataKey] !== undefined) {
//       let dataToPush;
//       if (this.businessType[0] === 'leadgen') {
//         if (projectsMap.projects.leadgen.meta[dataKey]) {
//           title = projectsMap.projects.leadgen.meta[dataKey];
//           dataToPush = this.getFormattedText(summaryData[dataKey], title);
//         }
//       } else {
//         if (projectsMap.projects.default.meta[dataKey]) {
//           title = projectsMap.projects.default.meta[dataKey];
//           dataToPush = this.getFormattedText(summaryData[dataKey], title);
//         }
//       }
//       if (dataToPush) {
//         statisticsItems.push(dataToPush);
//       }
//     }
//   }

//   // Push any remaining items not found in order array
//   Object.keys(summaryData).forEach(dataKey => {
//     if (!order.includes(dataKey) && summaryData[dataKey] !== undefined) {
//       let title = dataKey.replace("_", " ").toLowerCase();
//       let dataToPush;
//       if (this.businessType[0] === 'leadgen') {
//         if (projectsMap.projects.leadgen.meta[dataKey]) {
//           title = projectsMap.projects.leadgen.meta[dataKey];
//           dataToPush = this.getFormattedText(summaryData[dataKey], title);
//         }
//       } else {
//         if (projectsMap.projects.default.meta[dataKey]) {
//           title = projectsMap.projects.default.meta[dataKey];
//           dataToPush = this.getFormattedText(summaryData[dataKey], title);
//         }
//       }
//       if (dataToPush) {
//         statisticsItems.push(dataToPush);
//       }
//     }
//   });
//   return statisticsItems;
// } 
  },
  data() {
    return {
      projectsMap:projectsMap
    };
  },
  methods: {
  getFormattedText(text, field) {
  field = field.toLowerCase();

   if (field === "clicks") {
    return {
      icon: "MousePointerIcon",
      color: "light-warning",
      title: millify(text),
      subtitle: "Clicks",
      customClass: "mb-2 mb-xl-0",
    };
  } 
 else if (field === "spends") {
    return {
      icon: "currency-rupee",
      color: "light-warning",
      title: millify(text),
      subtitle: "Spends",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "leads" && this.businessType[0] == 'leadgen') {
    return {
      icon: "currency-rupee",
      color: "light-info",
      title: millify(text),
      subtitle: "Leads",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "leads" && this.businessType[0] == 'leadform') {
    return {
      icon: "currency-rupee",
      color: "light-info",
      title: millify(text),
      subtitle: "Leads",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "conversion value" && this.businessType[0] == 'ecommerce') {
    return {
      icon: "currency-rupee",
      color: "light-info",
      title: millify(text),
      subtitle: "Revenue",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "web purchases" && this.businessType[0] == 'ecommerce') {
    return {
      icon: "currency-rupee",
      color: "light-info",
      title: millify(text),
      subtitle: "Purchases",
      customClass: "mb-2 mb-xl-0",
    };
  }
  // else if (field === "conversions") {
  //   return {
  //     icon: "currency-rupee",
  //     color: "light-info",
  //     title: millify(text),
  //     subtitle: "Purchases",
  //     customClass: "mb-2 mb-xl-0",
  //   };
  // }
  else if (field === "cpm" && this.businessType[0] !== 'leadgen') {
    return {
      icon: "currency-rupee",
      color: "light-info",
      title: millify(text),
      subtitle: "CPM",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "cpl" && this.businessType[0] == 'leadgen') {
    return {
      icon: "MousePointerIcon",
      color: "light-warning",
      title: millify(text),
      subtitle: "CPL",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "cpm" && this.businessType[0] !== 'leadform') {
    return {
      icon: "currency-rupee",
      color: "light-info",
      title: millify(text),
      subtitle: "CPM",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "cpl" && this.businessType[0] == 'leadform') {
    return {
      icon: "MousePointerIcon",
      color: "light-warning",
      title: millify(text),
      subtitle: "CPL",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "impressions") {
    return {
      icon: "EyeIcon",
      color: "light-info",
      title: millify(text),
      subtitle: "Impressions",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "conversion value") {
    return {
      icon: "EyeIcon",
      color: "light-info",
      title: millify(text),
      subtitle: "Conversion Value",
      customClass: "mb-2 mb-xl-0",
    };
  }
  else if (field === "cpr") {
    return {
      icon: "EyeIcon",
      color: "light-info",
      title: millify(text),
      subtitle: "CPR",
      customClass: "mb-2 mb-xl-0",
    };
  }
   else if (field === "ctr") {
    return {
      icon: "EyeIcon",
      color: "light-pink",
      title: millify(text),
      subtitle: "CTR%",
      customClass: "mb-2 mb-xl-0",
    };
  } else if (field === "cpcl") {
    return {
      icon: "EyeIcon",
      color: "light-pink",
      title: millify(text),
      subtitle: "CPC",
      customClass: "mb-2 mb-xl-0",
    };
  } else if (field === "costMicros"  && this.businessType[0] != 'ecommerce' || field === "conversions" && this.businessType[0] != 'ecommerce') {
    return {
      icon: "EyeIcon",
      color: "light-pink",
      title: millify(text),
      subtitle: this.businessType[0] == ('leadgen' || 'leadform') ? 'Leads' : 'Conversions',
      customClass: "mb-2 mb-xl-0",
    };
  } else if (field === "roas" && this.businessType[0] !== 'leadgen') {
    return {
      icon: "TargetIcon",
      color: "light-pink",
      title: millify(text),
      subtitle: "ROAS",
      customClass: "mb-2 mb-xl-0",
    };
  }
},
    // getFormattedText(text, field) {
    //   field = field.toLowerCase();
    //   if (field === "clicks") {
    //     return {
    //       icon: "MousePointerIcon",
    //       color: "light-warning",
    //       title: millify(text),
    //       subtitle: "Clicks",
    //       customClass: "mb-2 mb-xl-0"
    //     };
    //   } else if (field === "impressions") {
    //     return {
    //       icon: "EyeIcon",
    //       color: "light-pink",
    //       title: millify(text),
    //       subtitle: "Impressions",
    //       customClass: "mb-2 mb-xl-0"
    //     };
    //   }
    //   else if (field === "conversionValue") {
    //     return {
    //       icon: "EyeIcon",
    //       color: "light-pink",
    //       title: millify(text),
    //       subtitle: "Conversion Value",
    //       customClass: "mb-2 mb-xl-0"
    //     };
    //   }
    //    else if (field === "spends") {
    //     let title = "";
    //     if (this.iconType === "USD") {
    //       title = "$";
    //     }
    //     if (this.iconType === "INR") {
    //       title = "₹";
    //     }
    //     return {
    //       icon: "currency-rupee",
    //       color: "light-info",
    //       title: title + " " + millify(text),
    //       subtitle: "Spends",
    //       customClass: "mb-2 mb-xl-0"
    //     };
    //   } else if (field === "ctr") {
    //     return {
    //       icon: "currency-rupee",
    //       color: "light-success",
    //       title: millify(text),
    //       subtitle: "CTR",
    //       customClass: "mb-2 mb-xl-0"
    //     };
    //   }
    //   else if (field === "cvr") {
    //     return {
    //       icon: "currency-rupee",
    //       color: "light-pink",
    //       title: millify(text),
    //       subtitle: "CVR",
    //       customClass: "mb-2 mb-xl-0"
    //     };
    //   }
    //    else if (field === "roas") {
    //     return {
    //       icon: "TargetIcon",
    //       color: "light-info",
    //       title: millify(text),
    //       subtitle: "ROAS",
    //       customClass: "mb-2 mb-xl-0"
    //     };
    //   }
    // }
  }
};
</script>

<style lang="scss">
.card-statistics {
  .card-body {
    padding: 1rem !important;
  }
}
</style>
