<template>
  <div class="multiHeaderContainer">
    <b-row>
      <b-col cols="12">
        <b-card style="margin-left: 10px; margin-right: 10px;">
          <div style="display: flex; justify-content: space-between">
            <div>
              <b-card-title>
                <h3 class="app-title mdsm">{{ projectNameSummary }}</h3>
              </b-card-title>
            </div>
            <div
              style="display: flex; justify-content: space-between; gap: 5px;"
            >
              <div
                style="display: flex; justify-content: space-between; gap: 5px;"
                v-if="
                  this.selectionType === 'multiple' &&
                    this.type != 'productWise' &&
                    this.type != 'zoneWise' &&
                    this.type != 'productMetaWise' &&
                    this.type != 'productGoogleAdsWise' &&
                    this.type != 'zoneGoogleAdsWise'
                "
              >
                <label>Project:</label>
                <v-select
                  style="margin-top: -13px; min-width: 250px;"
                  label="name"
                  placeholder="Select Project(s)"
                  v-model="filterDataProjectWiseSelection"
                  multiple
                  :options="filterOption"
                  :deselectFromDropdown="true"
                />
              </div>
              <div
                style="display: flex; justify-content: space-between; gap: 5px;"
                v-else-if="
                  this.type == 'zoneWise' && this.selectionType == 'single'
                "
              >
                <div
                  class="selectBox custom-vue-select"
                  style="margin-top: -25px; min-width: 160px; max-width: 250px; min-height: 20px"
                >
                  <label>Zone:</label>
                  <br />
                  <v-select
                    label="name"
                    placeholder="Select Zone(s)"
                    v-model="filterDataZoneWiseOverallType"
                    multiple
                    v-b-tooltip.hover.top="tooltipZoneWiseSelect"
                    :options="mergeZoneWiseOverallOptionsList"
                    @input="handleFilterDataZoneWiseType"
                    class="horizontal-scroll heightFixed selectBox-width"
                    :deselectFromDropdown="true"
                  />
                  <!-- <b-form-select style="text-transform: capitalize; font-size: 13px" :placeholder="selectBoxType"
                    v-model="filterDataZoneWiseType" @input="handleFilterDataZoneWiseType">
                    <option v-for="option in mergeZoneWiseOptionsList" :key="option" :value="option">
                      <span>
                        {{ option }}
                      </span>
                    </option>
                  </b-form-select> -->
                </div>
                <div
                  class="selectBox custom-vue-select"
                  style="margin-top: -25px; min-width: 250px; max-width: 270px; min-height: 20px"
                >
                  <label>Projects:</label>
                  <br />
                  <v-select
                    :disabled="this.filterDataZoneWiseOverallType.length === 0"
                    label="name"
                    placeholder="Select Project(s)"
                    v-model="sourceProjectsOverallType"
                    multiple
                    v-b-tooltip.hover.top="tooltipZoneWiseOverallMessage"
                    :options="mergedProjectWiseOverallData"
                    @input="handleZoneListChange"
                    class="horizontal-scroll heightFixed"
                    :deselectFromDropdown="true"
                  />
                </div>
                <div class="selectBox" style="margin-top: -25px;">
                  <label>Channel:</label>
                  <br />
                  <b-form-select
                    style="text-transform: capitalize;"
                    :options="sourceType"
                    label="title"
                    :clearable="false"
                    v-model="selectedSource"
                    @input="handleMetricsChange"
                  >
                    <template v-slot:option="option">
                      <span :class="option"></span>
                      <span v-if="option.title == 'all'">
                        All
                      </span>
                      <span v-else-if="option.title == 'googleAds'">
                        Google Ads
                      </span>
                      <span v-else-if="option.title == 'meta'">
                        Meta
                      </span>
                      <span v-else>
                        {{ option.title }}
                      </span>
                    </template>
                  </b-form-select>
                </div>
                <div class="selectBox" style="margin-top: -25px; ">
                  <label>Product:</label>
                  <br />
                  <b-form-select
                    style=" text-transform: capitalize; min-width: 140px;"
                    :options="mergedZoneWiseData"
                    label="title"
                    :clearable="false"
                    v-model="selectedZoneType"
                    @input="handleZoneWiseChange"
                  >
                    <template v-slot:option="option">
                      <span :class="option"></span>
                      <span v-if="option.title == 'all'">
                        All
                      </span>
                      <span v-else-if="option.title == 'googleAds'">
                        Google Ads
                      </span>
                      <span v-else-if="option.title == 'meta'">
                        Meta
                      </span>
                      <span v-else>
                        {{ option.title }}
                      </span>
                    </template>
                  </b-form-select>
                </div>
                <div
                  class="selectBox"
                  style="margin-top: -25px;"
                  v-if="
                    this.selectedZoneType == 'LeadGen' &&
                      this.clientId === '661674af15a991dc751dda34'
                  "
                >
                  <label>Sub-Product:</label>
                  <br />
                  <b-form-select
                    style=" text-transform: capitalize;min-width: 140px;"
                    :options="mergedSubProjectData"
                    label="title"
                    :clearable="false"
                    v-model="subProjectSelections"
                    @input="handleSubOptionsChange"
                  >
                    <template v-slot:option="option">
                      <span :class="option"></span>
                      <span v-if="option.title == 'all'">
                        All
                      </span>
                      <span v-else-if="option.title == 'googleAds'">
                        Google Ads
                      </span>
                      <span v-else-if="option.title == 'meta'">
                        Meta
                      </span>
                      <span v-else>
                        {{ option.title }}
                      </span>
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div
                style="display: flex; justify-content: space-between; gap: 5px;"
                v-else-if="
                  this.type == 'zoneMetaWise' && this.selectionType == 'single'
                "
              >
                <div
                  class="selectBox custom-vue-select"
                  style="margin-top: -20px; min-width: 160px; max-width: 250px; min-height: 20px"
                >
                  <label>Zone:</label>
                  <br />
                  <v-select
                    label="name"
                    placeholder="Select Zone(s)"
                    v-model="filterDataZoneWiseMetaType"
                    multiple
                    v-b-tooltip.hover.top="tooltipMetaZoneWiseSelect"
                    :options="mergeZoneWiseMetaOptionsList"
                    @input="handleFilterDataZoneWiseType"
                    class="horizontal-scroll heightFixed"
                    :deselectFromDropdown="true"
                  />
                  <!-- <b-form-select style="text-transform: capitalize; font-size: 13px ;height: 50px"
                    :placeholder="selectBoxType" v-model="filterDataZoneWiseType" @input="handleFilterDataZoneWiseType">
                    <option v-for="option in mergeZoneWiseOptionsList" :key="option" :value="option">
                      <span>
                        {{ option }}
                      </span>
                    </option>
                  </b-form-select> -->
                </div>
                <div
                  class="selectBox custom-vue-select"
                  style="margin-top: -20px;  min-width: 250px; max-width: 270px; min-height: 20px"
                >
                  <label>Projects:</label>
                  <br />
                  <v-select
                    :disabled="this.filterDataZoneWiseMetaType.length === 0"
                    label="name"
                    placeholder="Select Project(s)"
                    v-model="sourceProjectsMetaType"
                    multiple
                    v-b-tooltip.hover.top="tooltipZoneWiseMetaMessage"
                    :options="mergedProjectWiseMetaData"
                    @input="handleZoneListChange"
                    class="horizontal-scroll heightFixed"
                    :deselectFromDropdown="true"
                  />
                </div>
                <div
                  style="margin-top: -20px; min-width: 160px; max-width: 200px; min-height: 20px"
                >
                  <label>Product:</label>
                  <!-- <br/> -->
                  <b-form-select
                    class="single-select"
                    style="text-transform: capitalize; height: 48px;"
                    :options="mergedMetaZoneWiseData"
                    label="title"
                    :clearable="false"
                    v-model="selectedZoneType"
                    @input="handleZoneWiseChange"
                  >
                    <template v-slot:option="option">
                      <span :class="option"></span>
                      <span v-if="option.title == 'all'">
                        All
                      </span>
                      <span v-else-if="option.title == 'googleAds'">
                        Google Ads
                      </span>
                      <span v-else-if="option.title == 'meta'">
                        Meta
                      </span>
                      <span v-else>
                        {{ option.title }}
                      </span>
                    </template>
                  </b-form-select>
                </div>
                <div
                  class="selectBox"
                  style="margin-top: -20px; min-width: 160px; max-width: 200px; min-height: 20px"
                  v-if="
                    this.selectedZoneType == 'LeadGen' &&
                      this.clientId === '661674af15a991dc751dda34'
                  "
                >
                  <label>Sub-Product:</label>
                  <br />
                  <b-form-select
                    class="single-select"
                    style="text-transform: capitalize; height: 48px;"
                    :options="mergedSubProjectData"
                    label="title"
                    :clearable="false"
                    v-model="subProjectSelections"
                    @input="handleSubOptionsChange"
                  >
                    <template v-slot:option="option">
                      <span :class="option"></span>
                      <span v-if="option.title == 'all'">
                        All
                      </span>
                      <span v-else-if="option.title == 'googleAds'">
                        Google Ads
                      </span>
                      <span v-else-if="option.title == 'meta'">
                        Meta
                      </span>
                      <span v-else>
                        {{ option.title }}
                      </span>
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div
                style="display: flex; justify-content: space-between; gap: 5px;"
                v-else-if="
                  this.type == 'zoneGoogleAdsWise' &&
                    this.selectionType == 'single'
                "
              >
                <div
                  class="selectBox custom-vue-select"
                  style="margin-top: -18px; min-width: 160px; max-width: 250px; min-height: 20px"
                >
                  <label>Zone:</label>
                  <br />
                  <!-- <b-form-select style="text-transform: capitalize; font-size: 13px; height: 50px"
                    :placeholder="selectBoxType" v-model="filterDataZoneWiseType" @input="handleFilterDataZoneWiseType">
                    <option v-for="option in mergeZoneWiseOptionsList" :key="option" :value="option">
                      <span>
                        {{ option }}
                      </span>
                    </option>
                  </b-form-select> -->
                  <v-select
                    label="name"
                    placeholder="Select Zone(s)"
                    v-model="filterDataZoneWiseGoogleType"
                    multiple
                    v-b-tooltip.hover.top="tooltipGoogleZoneWiseSelect"
                    :options="mergeZoneWiseGoogleOptionsList"
                    @input="handleFilterDataZoneWiseType"
                    class="horizontal-scroll heightFixed"
                    :deselectFromDropdown="true"
                  />
                </div>
                <div
                  class="selectBox custom-vue-select"
                  style="margin-top: -18px; min-width: 250px; max-width: 270px; min-height: 20px"
                >
                  <label>Projects:</label>
                  <br />
                  <v-select
                    label="name"
                    :disabled="this.filterDataZoneWiseGoogleType.length === 0"
                    placeholder="Select Project(s)"
                    v-model="sourceProjectsGoogleType"
                    multiple
                    v-b-tooltip.hover.top="tooltipZoneWiseGoogleMessage"
                    :options="mergedProjectWiseGoogleData"
                    @input="handleZoneListChange"
                    class="horizontal-scroll heightFixed"
                    :deselectFromDropdown="true"
                  />
                </div>
                <div
                  style="margin-top: -18px; min-width: 160px; max-width: 200px; min-height: 20px"
                >
                  <label>Product:</label>
                  <b-form-select
                    class="single-select"
                    style="text-transform: capitalize;"
                    :options="mergedGoogleZoneWiseData"
                    label="title"
                    :clearable="false"
                    v-model="selectedZoneType"
                    @input="handleZoneWiseChange"
                  >
                    <template v-slot:option="option">
                      <span :class="option"></span>
                      <span v-if="option.title == 'all'">
                        All
                      </span>
                      <span v-else-if="option.title == 'googleAds'">
                        Google Ads
                      </span>
                      <span v-else-if="option.title == 'meta'">
                        Meta
                      </span>
                      <span v-else>
                        {{ option.title }}
                      </span>
                    </template>
                  </b-form-select>
                </div>
              </div>
              <div
                v-else-if="
                  this.type == 'productWise' && this.selectionType == 'multiple'
                "
                style="display: flex; justify-content: space-between; gap: 5px;"
              >
                <div
                  class="selectBox custom-vue-select"
                  style="margin-top: -25px;  min-width: 250px; max-width: 270px; min-height: 20px"
                  v-if="this.hideSelectBox !== 'google'"
                >
                  <label>Projects:</label>
                  <br />
                  <v-select
                    label="name"
                    placeholder="Select Project(s)"
                    v-model="filterDataProductProjectsSelection"
                    multiple
                    :options="mergeAllProjectNames"
                    class="horizontal-scroll heightFixed"
                    @input="handleZoneListProjectChange"
                    :deselectFromDropdown="true"
                  />
                </div>
                <div
                  v-if="this.hideSelectBox !== 'google'"
                  class="selectBox"
                  style="margin-top: -25px;"
                >
                  <label>Channel:</label>
                  <br />
                  <b-form-select
                    style="text-transform: capitalize;"
                    :options="sourceType"
                    label="title"
                    :clearable="false"
                    v-model="selectedProductSource"
                    @input="handleProductMetricsChange"
                  >
                    <template v-slot:option="option">
                      <span :class="option"></span>
                      <span v-if="option.title == 'all'">
                        All
                      </span>
                      <span v-else-if="option.title == 'googleAds'">
                        Google Ads
                      </span>
                      <span v-else-if="option.title == 'meta'">
                        Meta
                      </span>
                      <span v-else>
                        {{ option.title }}
                      </span>
                    </template>
                  </b-form-select>
                </div>
                <div
                  class="selectBox custom-vue-select"
                  style="margin-top: -25px; min-width: 160px; max-width: 200px; min-height: 20px"
                >
                  <label>Product:</label>
                  <br />
                  <v-select
                    label="name"
                    placeholder="Select Product(s)"
                    v-model="filterDataProductSelection"
                    multiple
                    :options="filterProductWiseOption"
                    class="horizontal-scroll heightFixed"
                    :deselectFromDropdown="true"
                  />
                </div>
              </div>
              <div
                v-else-if="
                  this.type == 'productGoogleAdsWise' &&
                    this.selectionType == 'multiple'
                "
                style="display: flex; justify-content: space-between; gap: 5px;"
              >
                <div
                  class="selectBox custom-vue-select"
                  style="margin-top: -18px; min-width: 250px; max-width: 270px; min-height: 20px"
                >
                  <label>Projects:</label>
                  <br />
                  <v-select
                    label="name"
                    placeholder="Select Project(s)"
                    v-model="filterDataProductProjectsSelection"
                    multiple
                    :options="mergeAllProjectNames"
                    class="horizontal-scroll heightFixed"
                    @input="handleZoneListProjectChange"
                    :deselectFromDropdown="true"
                  />
                </div>
                <div
                  class="selectBox custom-vue-select"
                  style="margin-top: -18px; min-width: 160px; max-width: 200px; min-height: 20px"
                >
                  <label>Product:</label>
                  <br />
                  <v-select
                    label="name"
                    placeholder="Select Product(s)"
                    v-model="filterDataProductSelection"
                    multiple
                    :options="filterProductWiseOption"
                    class="horizontal-scroll heightFixed"
                  />
                </div>
              </div>
              <div
                v-else-if="
                  this.type == 'productMetaWise' &&
                    this.selectionType == 'multiple'
                "
                style="display: flex; justify-content: space-between; gap: 5px;"
              >
                <div
                  class="selectBox custom-vue-select"
                  style="margin-top: -20px;  min-width: 250px; max-width: 270px; min-height: 20px"
                  v-if="this.hideSelectBox !== 'meta'"
                >
                  <label>Projects:</label>
                  <br />
                  <v-select
                    label="name"
                    placeholder="Select Project(s)"
                    v-model="filterDataProductProjectsSelection"
                    multiple
                    :options="mergeAllProjectNames"
                    class="horizontal-scroll heightFixed"
                    @input="handleZoneListProjectChange"
                    :deselectFromDropdown="true"
                  />
                </div>
                <div
                  class="selectBox custom-vue-select"
                  style="margin-top: -20px; min-width: 160px; max-width: 200px; min-height: 20px"
                >
                  <label>Product:</label>
                  <br />
                  <v-select
                    label="name"
                    placeholder="Select Product(s)"
                    v-model="filterDataProductSelection"
                    multiple
                    :options="filterProductWiseOption"
                    class="horizontal-scroll heightFixed"
                    :deselectFromDropdown="true"
                  />
                </div>
                <!-- <label>Product:</label>
                <v-select label="name" placeholder="Select Product(s)" v-model="filterDataProductSelection" multiple
                  :options="filterProductWiseOption" /> -->
              </div>
              <div
                v-else
                style="margin-top: -15px; min-width: 100px; margin-bottom: 10px;"
              >
                <label>Projects:</label>
                <v-select
                  label="name"
                  placeholder="Select Project(s)"
                  :placeholder="selectBoxType"
                  v-model="filterDataProjectSelection"
                  multiple
                  :options="filterOption"
                  :deselectFromDropdown="true"
                />
              </div>
            </div>
          </div>
          <div v-if="this.type == 'googleAds'">
            <div class="tables-google-container">
              <div class="date-column">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <feather-icon
                          icon="DownloadIcon"
                          color="#200E6B"
                          size="18"
                          style="cursor: pointer; margin-top:5px"
                          @click="
                            downReportProjectsEntirely('Project_Insights')
                          "
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in filteredTableData &&
                        filteredTableData[0].dateWiseData"
                      :key="index"
                    >
                      <td class="dateProjectTd padding0">
                        <span v-if="item.date == 'Total'"
                          ><b>{{ item.date }}</b></span
                        >
                        <span v-else> {{ item.date }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-for="(item, index) in filteredTableData" :key="index">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        colspan="10"
                        class="mainTh"
                        :style="{
                          backgroundColor: getProjectsBackgroundColor(index),
                        }"
                      >
                        <span
                          style="display: flex; justify-content: space-between; align-items: center;"
                        >
                          <span></span>
                          <span style="margin-top: 10px; float: right;">{{
                            item.projectName
                          }}</span>
                          <span style="float: right;"
                            ><feather-icon
                              icon="DownloadIcon"
                              color="#200E6B"
                              size="18"
                              style=" cursor: pointer;"
                              @click="
                                exportData(item.dateWiseData, item.projectName)
                              "
                          /></span>
                        </span>
                      </th>
                    </tr>
                    <tr v-if="businessTypeProject == 'leadgen'">
                      <!-- <template v-if="index === 0">
                    <th>Date</th>
                  </template> -->
                      <th
                        v-for="(value, key) in projectsMap.projects['leadgen']
                          .googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else-if="businessTypeProject == 'leadform'">
                      <!-- <template v-if="index === 0">
                    <th>Date</th>
                  </template> -->
                      <th
                        v-for="(value, key) in projectsMap.projects['leadform']
                          .googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else>
                      <template v-if="index === 0">
                        <th>Date</th>
                      </template>
                      <th
                        v-for="(value, key) in projectsMap.projects['default']
                          .googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, dataIndex) in item.dateWiseData"
                      :key="dataIndex"
                    >
                      <template v-if="businessTypeProject == 'leadgen'">
                        <!-- <template v-if="index === 0">
                      <td style="color: gray ; font-size: 12px;" class="dateTd">
                        {{ data.date }}
                      </td>
                    </template> -->
                        <td
                          v-for="(value, key) in projectsMap.projects['leadgen']
                            .googleAds"
                          :key="key"
                        >
                          <!-- {{ removeINR(data.insights[key]) }} -->
                          <span v-if="data.date == 'Total'"
                            ><b>{{ data.insights[key] }}</b></span
                          ><span v-else>{{ data.insights[key] }}</span>
                        </td>
                      </template>
                      <template v-else-if="businessTypeProject == 'leadform'">
                        <!-- <template v-if="index === 0">
                      <td style="color: gray ; font-size: 12px;" class="dateTd">
                        {{ data.date }}
                      </td>
                    </template> -->
                        <td
                          v-for="(value, key) in projectsMap.projects['leadform']
                            .googleAds"
                          :key="key"
                        >
                          <!-- {{ removeINR(data.insights[key]) }} -->
                          <span v-if="data.date == 'Total'"
                            ><b>{{ data.insights[key] }}</b></span
                          ><span v-else>{{ data.insights[key] }}</span>
                        </td>
                      </template>
                      <template v-else>
                        <template v-if="index === 0">
                          <td
                            style="color: gray ; font-size: 12px;"
                            class="dateTd"
                          >
                            {{ data.date }}
                          </td>
                        </template>
                        <td
                          v-for="(value, key) in projectsMap.projects['default']
                            .googleAds"
                          :key="key"
                        >
                          <!-- {{ removeINR(data.insights[key]) }} -->
                          {{ data.insights[key] }}
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="this.type == 'facebookAds'">
            <div class="tables-meta-container">
              <div class="date-column">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <feather-icon
                          icon="DownloadIcon"
                          color="#200E6B"
                          size="18"
                          style="cursor: pointer; margin-top:5px"
                          @click="
                            downReportProjectsEntirely('Project_Insights')
                          "
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in filteredTableData &&
                        filteredTableData[0].dateWiseData"
                      :key="index"
                    >
                      <td class="dateFacebookProjectTd paddingMeta0">
                        <span v-if="item.date == 'Total'"
                          ><b>{{ item.date }}</b></span
                        >
                        <span v-else> {{ item.date }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-for="(item, index) in filteredTableData" :key="index">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        colspan="10"
                        class="mainTh"
                        :style="{
                          backgroundColor: getProjectsBackgroundColor(index),
                        }"
                      >
                        <span
                          style="display: flex; justify-content: space-between; align-items: center;"
                        >
                          <span></span>
                          <span style="margin-top: 10px; float: right;">{{
                            item.projectName
                          }}</span>
                          <span style="float: right;"
                            ><feather-icon
                              icon="DownloadIcon"
                              color="#200E6B"
                              size="18"
                              style=" cursor: pointer;"
                              @click="
                                exportData(item.dateWiseData, item.projectName)
                              "
                          /></span>
                        </span>
                      </th>
                    </tr>
                    <tr v-if="businessTypeProject == 'leadgen'">
                      <!-- <template v-if="index === 0">
                    <th>Date</th>
                  </template> -->
                      <th
                        v-for="(value, key) in projectsMap.projects['leadgen']
                          .meta"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else-if="businessTypeProject == 'leadform'">
                      <!-- <template v-if="index === 0">
                    <th>Date</th>
                  </template> -->
                      <th
                        v-for="(value, key) in projectsMap.projects['leadform']
                          .meta"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else>
                      <template v-if="index === 0">
                        <th>Date</th>
                      </template>
                      <th
                        v-for="(value, key) in projectsMap.projects['default']
                          .meta"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, dataIndex) in item.dateWiseData"
                      :key="dataIndex"
                    >
                      <template v-if="businessTypeProject == 'leadgen'">
                        <!-- <template v-if="index === 0">
                      <td class="padding0" style="color: gray ; font-size: 12px;">
                        {{ data.date }}
                      </td>
                    </template> -->
                        <td
                          v-for="(value, key) in projectsMap.projects['leadgen']
                            .meta"
                          :key="key"
                        >
                          <!-- {{ data.insights[key] }} -->
                          <span
                            v-if="data.date == 'total' || data.date == 'Total'"
                            ><b>{{ data.insights[key] }}</b></span
                          ><span v-else>{{ data.insights[key] }}</span>
                        </td>
                      </template>
                      <template v-else-if="businessTypeProject == 'leadform'">
                        <!-- <template v-if="index === 0">
                      <td class="padding0" style="color: gray ; font-size: 12px;">
                        {{ data.date }}
                      </td>
                    </template> -->
                        <td
                          v-for="(value, key) in projectsMap.projects['leadform']
                            .meta"
                          :key="key"
                        >
                          <!-- {{ data.insights[key] }} -->
                          <span
                            v-if="data.date == 'total' || data.date == 'Total'"
                            ><b>{{ data.insights[key] }}</b></span
                          ><span v-else>{{ data.insights[key] }}</span>
                        </td>
                      </template>
                      <template v-else>
                        <template v-if="index === 0">
                          <td
                            class="padding0"
                            style="color: gray ; font-size: 12px;"
                          >
                            {{ data.date }}
                          </td>
                        </template>
                        <td
                          v-for="(value, key) in projectsMap.projects['default']
                            .meta"
                          :key="key"
                        >
                          {{ data.insights[key] }}
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="this.type == 'zoneWise'">
            <div
              class="spinnerCentered"
              v-if="zoneWiseDataLoading || zoneWiseOptionsLoad === 0"
            >
              <multiChannelSpinner />
            </div>
            <div
              class="spinnerCentered p-2"
              v-else-if="filteredOverAllZonewiseTableData.length === 0"
            >
              Data Not Found.
            </div>
            <div v-else class="tables-meta-container">
              <div class="date-column">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <feather-icon
                          icon="DownloadIcon"
                          color="#200E6B"
                          size="18"
                          style="cursor: pointer; margin-top:5px"
                          @click="downReportEntirely('Zonewize_Insights')"
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <template v-if="this.reportType == 'monthly'">
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in filteredOverAllZonewiseTableData &&
                          filteredOverAllZonewiseTableData[0] &&
                          filteredOverAllZonewiseTableData[0].dateWiseData"
                        :key="index"
                      >
                        <td class="dateFacebookProjectTd paddingMeta0">
                          <span v-if="item.date == 'total'"
                            ><b>{{ item.date }}</b></span
                          >
                          <span v-else>{{ convertMonthDate(item.date) }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-if="this.reportType == 'weekly'">
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in filteredOverAllZonewiseTableData &&
                          filteredOverAllZonewiseTableData[0] &&
                          filteredOverAllZonewiseTableData[0].dateWiseData"
                        :key="index"
                      >
                        <td class="dateFacebookProjectTd paddingMeta0">
                          <span v-if="item.date == 'total'"
                            ><b>{{ item.date }}</b></span
                          >
                          <span v-else> Week of {{ item.date }} </span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                  <template v-if="this.reportType == 'daily'">
                    <tbody>
                      <tr
                        v-for="(item,
                        index) in filteredOverAllZonewiseTableData &&
                          filteredOverAllZonewiseTableData[0] &&
                          filteredOverAllZonewiseTableData[0].dateWiseData"
                        :key="index"
                      >
                        <td class="dateFacebookProjectTd paddingMeta0">
                          <span v-if="item.date == 'total'"
                            ><b>{{ item.date }}</b></span
                          >
                          <span v-else>{{ item.date }} </span>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </table>
              </div>
              <!-- <div v-if="this.filterDataZoneWiseType == 'all zones' || this.filterDataZoneWiseType == 'defaultOne'"> -->
              <div
                v-for="(item, index) in filteredOverAllZonewiseTableData"
                :key="index"
              >
                <table class="table">
                  <template v-if="rangeType == 'daily'">
                    <thead>
                      <tr>
                        <th
                          :colspan="salesforceDataFound ? '14' : '10'"
                          class="mainTh"
                          :style="{
                            backgroundColor: getProjectsBackgroundColor(index),
                          }"
                        >
                          <span
                            style="display: flex; justify-content: center; align-items: center; position: relative;"
                          >
                            <span
                              style="margin-top: 10px; text-align: center; flex-grow: 1;"
                            >
                              <span>{{ item.groupName }}</span>
                            </span>
                            <span style="position: absolute; right: 0;">
                              <feather-icon
                                icon="DownloadIcon"
                                color="#200E6B"
                                size="18"
                                style="cursor: pointer;"
                                @click="
                                  exportData(item.dateWiseData, item.groupName)
                                "
                              />
                            </span>
                          </span>
                        </th>
                      </tr>
                      <tr v-if="businessTypeProject == 'leadgen' && salesforceDataFound">
                        <!-- <template v-if="index === 0">
                      <th>Date</th>
                    </template> -->
                        <th
                          v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                            'leadgen'
                          ].withLeadSalesforce"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </tr>
                      <tr v-else-if="businessTypeProject == 'leadgen' && !salesforceDataFound">
                        <!-- <template v-if="index === 0">
                      <th>Date</th>
                    </template> -->
                        <th
                          v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                            'leadgen'
                          ].googleAds"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </tr>
                      <tr v-else-if="businessTypeProject == 'leadform' && salesforceDataFound">
                        <!-- <template v-if="index === 0">
                      <th>Date</th>
                    </template> -->
                        <th
                          v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                            'leadform'
                          ].withLeadSalesforce"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </tr>
                      <tr v-else-if="businessTypeProject == 'leadform' && !salesforceDataFound">
                        <!-- <template v-if="index === 0">
                      <th>Date</th>
                    </template> -->
                        <th
                          v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                            'leadform'
                          ].googleAds"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </tr>
                      <tr v-else>
                        <template v-if="index === 0">
                          <th>Date</th>
                        </template>
                        <th
                          v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                            'default'
                          ].googleAds"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-if="
                          selectedSource == 'all' || selectedSource == 'googleAds'
                        "
                      >
                        <tr
                          v-for="(data, dataIndex) in item.dateWiseData"
                          :key="dataIndex"
                        >
                        <template v-if="businessTypeProject == 'leadgen' && salesforceDataFound">
                          <td
                            v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                              'leadgen'
                            ].withLeadSalesforce"
                            :key="key"
                          >
                            <!-- <span v-if="data.date == 'total'"><b>{{ data.insights[key] }}</b></span>
                           <span v-else>{{ data.insights[key] }}</span> -->
                            <span v-if="data.date == 'total'"
                              ><b
                                >
                                <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                                  {{ data &&  data.insights &&  data.insights.salesforce && data.insights.salesforce[key] }}
                                </span>
                                <span v-else>
                                  {{ data.insights[key] }}
                                </span>
                                </b
                            ></span>
                            <span  v-else-if="key === 'actions'"  v-b-tooltip.hover.bottom:title="isDisabledActions? 'Select Google Ads or Meta to enable the button.' :'' ">
                              <span
                            
                              
                           :style="{ cursor: isDisabledActions || item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? 'not-allowed' : 'pointer', pointerEvents: isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall'? 'none' : 'auto' }"
                            
                        > 
                            <b-icon
                                @click="openTargetVsAchievedVal(item.groupName)"
                                class="upDownIcon"
                                font-scale="1.5"
                                icon="pencil-square"
                                :color="isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? '#C7C8CC' :'#200E6B'"
                            />
                        </span>
                            </span>
                        
                            <span v-else>
                              <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                                {{ data &&  data.insights &&  data.insights.salesforce && data.insights.salesforce[key] }}
                              </span>
                              <span v-else>
                                {{ data.insights[key] }}
                              </span>
                            </span>
                          </td>
                        </template>
                          <template v-else-if="businessTypeProject == 'leadgen'">
                            <td
                              v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                                'leadgen'
                              ].googleAds"
                              :key="key"
                            >
                              <!-- <span v-if="data.date == 'total'"><b>{{ data.insights[key] }}</b></span>
                             <span v-else>{{ data.insights[key] }}</span> -->
                              <span v-if="data.date == 'total'"
                                ><b
                                  >{{ data.insights[key]
                                  }}</b
                              ></span>
                              <span  v-else-if="key === 'actions'"  v-b-tooltip.hover.bottom:title="isDisabledActions? 'Select Google Ads or Meta to enable the button.' :'' ">
                                <span
                              
                                
                             :style="{ cursor: isDisabledActions || item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? 'not-allowed' : 'pointer', pointerEvents: isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall'? 'none' : 'auto' }"
                              
                          > 
                              <b-icon
                                  @click="openTargetVsAchievedVal(item.groupName)"
                                  class="upDownIcon"
                                  font-scale="1.5"
                                  icon="pencil-square"
                                  :color="isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? '#C7C8CC' :'#200E6B'"
                              />
                          </span>
                              </span>
                          
                              <span v-else>{{ data.insights[key] }}
                                  
                              </span>
                            </td>
                          </template>
                          <template v-else-if="businessTypeProject == 'leadform'">
                            <td
                              v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                                'leadform'
                              ].googleAds"
                              :key="key"
                            >
                              <!-- <span v-if="data.date == 'total'"><b>{{ data.insights[key] }}</b></span>
                             <span v-else>{{ data.insights[key] }}</span> -->
                              <span v-if="data.date == 'total'"
                                ><b
                                  >{{ data.insights[key]
                                  }}</b
                              ></span>
                              <span  v-else-if="key === 'actions'"  v-b-tooltip.hover.bottom:title="isDisabledActions? 'Select Google Ads or Meta to enable the button.' :'' ">
                                <span
                              
                                
                             :style="{ cursor: isDisabledActions || item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? 'not-allowed' : 'pointer', pointerEvents: isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall'? 'none' : 'auto' }"
                              
                          > 
                              <b-icon
                                  @click="openTargetVsAchievedVal(item.groupName)"
                                  class="upDownIcon"
                                  font-scale="1.5"
                                  icon="pencil-square"
                                  :color="isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? '#C7C8CC' :'#200E6B'"
                              />
                          </span>
                              </span>
                          
                              <span v-else>{{ data.insights[key] }}
                                  
                              </span>
                            </td>
                          </template>
                          <template v-else>
                            <template v-if="index === 0">
                              <td
                                class="padding0"
                                style="color: gray ; font-size: 12px;"
                              >
                                {{ data.date }}
                              </td>
                            </template>
                            <td
                              v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                                'default'
                              ].meta"
                              :key="key"
                            >
                              {{ data.insights[key] }}
                            </td>
                          </template>
                        </tr>
                      </template>
                      <template v-if="selectedSource == 'meta'">
                        <tr
                          v-for="(data, dataIndex) in item.dateWiseData"
                          :key="dataIndex"
                        >
                        <template v-if="businessTypeProject == 'leadgen' && salesforceDataFound">
                          <td
                            v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                              'leadgen'
                            ].withLeadSalesforce"
                            :key="key"
                          >
                            <!-- <span v-if="data.date == 'total'"><b>{{ data.insights[key] }}</b></span>
                           <span v-else>{{ data.insights[key] }}</span> -->
                            <span v-if="data.date == 'total'"
                              ><b
                                >
                                <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                                  {{ data &&  data.insights &&  data.insights.salesforce && data.insights.salesforce[key] }}
                                </span>
                                <span v-else>
                                  {{ data.insights[key] }}
                                </span>
                                </b
                            ></span>
                            <span  v-else-if="key === 'actions'"  v-b-tooltip.hover.bottom:title="isDisabledActions? 'Select Google Ads or Meta to enable the button.' :'' ">
                              <span
                            
                              
                           :style="{ cursor: isDisabledActions || item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? 'not-allowed' : 'pointer', pointerEvents: isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall'? 'none' : 'auto' }"
                            
                        > 
                            <b-icon
                                @click="openTargetVsAchievedVal(item.groupName)"
                                class="upDownIcon"
                                font-scale="1.5"
                                icon="pencil-square"
                                :color="isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? '#C7C8CC' :'#200E6B'"
                            />
                        </span>
                            </span>
                        
                            <span v-else>
                              <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                                {{ data &&  data.insights &&  data.insights.salesforce && data.insights.salesforce[key] }}
                              </span>
                              <span v-else>
                                {{ data.insights[key] }}
                              </span>
                            </span>
                          </td>
                        </template>
                        <template v-if="businessTypeProject == 'leadform' && salesforceDataFound">
                          <td
                            v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                              'leadform'
                            ].withLeadSalesforce"
                            :key="key"
                          >
                            <!-- <span v-if="data.date == 'total'"><b>{{ data.insights[key] }}</b></span>
                           <span v-else>{{ data.insights[key] }}</span> -->
                            <span v-if="data.date == 'total'"
                              ><b
                                >
                                <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                                  {{ data &&  data.insights &&  data.insights.salesforce && data.insights.salesforce[key] }}
                                </span>
                                <span v-else>
                                  {{ data.insights[key] }}
                                </span>
                                </b
                            ></span>
                            <span  v-else-if="key === 'actions'"  v-b-tooltip.hover.bottom:title="isDisabledActions? 'Select Google Ads or Meta to enable the button.' :'' ">
                              <span
                            
                              
                           :style="{ cursor: isDisabledActions || item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? 'not-allowed' : 'pointer', pointerEvents: isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall'? 'none' : 'auto' }"
                            
                        > 
                            <b-icon
                                @click="openTargetVsAchievedVal(item.groupName)"
                                class="upDownIcon"
                                font-scale="1.5"
                                icon="pencil-square"
                                :color="isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? '#C7C8CC' :'#200E6B'"
                            />
                        </span>
                            </span>
                        
                            <span v-else>
                              <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                                {{ data &&  data.insights &&  data.insights.salesforce && data.insights.salesforce[key] }}
                              </span>
                              <span v-else>
                                {{ data.insights[key] }}
                              </span>
                            </span>
                          </td>
                        </template>
                          <template v-else-if="businessTypeProject == 'leadgen'">
                            <td
                            v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                              'leadgen'
                            ].meta"
                            :key="key"
                          >
                            <!-- <span v-if="data.date == 'total'"><b>{{ data.insights[key] }}</b></span>
                           <span v-else>{{ data.insights[key] }}</span> -->
                            <span v-if="data.date == 'total'"
                              ><b
                                >{{ data.insights[key]
                                }}</b
                            ></span>
                            <span
                            :style="{ cursor: isDisabledActions || item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? 'not-allowed' : 'pointer', pointerEvents: isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall'? 'none' : 'auto' }"
                            v-else-if="key === 'actions'"
                        >
                        
                            <b-icon
                                @click="openTargetVsAchievedVal(item.groupName)"
                                class="upDownIcon"
                                font-scale="1.5"
                                icon="pencil-square"
                               :color="isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? '#C7C8CC' :'#200E6B'"
                            />
                        </span>
                            <span v-else>{{ data.insights[key] }}</span>
                          </td>
                          </template>
                          <template v-else-if="businessTypeProject == 'leadform'">
                            <td
                            v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                              'leadform'
                            ].meta"
                            :key="key"
                          >
                            <!-- <span v-if="data.date == 'total'"><b>{{ data.insights[key] }}</b></span>
                           <span v-else>{{ data.insights[key] }}</span> -->
                            <span v-if="data.date == 'total'"
                              ><b
                                >{{ data.insights[key]
                                }}</b
                            ></span>
                            <span
                            :style="{ cursor: isDisabledActions || item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? 'not-allowed' : 'pointer', pointerEvents: isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall'? 'none' : 'auto' }"
                            v-else-if="key === 'actions'"
                        >
                        
                            <b-icon
                                @click="openTargetVsAchievedVal(item.groupName)"
                                class="upDownIcon"
                                font-scale="1.5"
                                icon="pencil-square"
                               :color="isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? '#C7C8CC' :'#200E6B'"
                            />
                        </span>
                            <span v-else>{{ data.insights[key] }}</span>
                          </td>
                          </template>
                          <template v-else>
                            <template v-if="index === 0">
                              <td
                                class="padding0"
                                style="color: gray ; font-size: 12px;"
                              >
                                {{ data.date }}
                              </td>
                            </template>
                            <td
                              v-for="(value, key) in projectsMap.zoneFilteredWiseData[
                                'default'
                              ].meta"
                              :key="key"
                            >
                              {{ data.insights[key] }}
                            </td>
                          </template>
                        </tr>
                      </template>
                    </tbody>
                  </template>
                  <template v-else>
                    <thead>
                      <tr>
                        <th
                          :colspan="salesforceDataFound ? '14' : '10'"
                          class="mainTh"
                          :style="{
                            backgroundColor: getProjectsBackgroundColor(index),
                          }"
                        >
                          <span
                            style="display: flex; justify-content: center; align-items: center; position: relative;"
                          >
                            <span
                              style="margin-top: 10px; text-align: center; flex-grow: 1;"
                            >
                              <span>{{ item.groupName }}</span>
                            </span>
                            <span style="position: absolute; right: 0;">
                              <feather-icon
                                icon="DownloadIcon"
                                color="#200E6B"
                                size="18"
                                style="cursor: pointer;"
                                @click="
                                  exportData(item.dateWiseData, item.groupName)
                                "
                              />
                            </span>
                          </span>
                        </th>
                      </tr>
                      <tr v-if="businessTypeProject == 'leadgen'">
                        <!-- <template v-if="index === 0">
                      <th>Date</th>
                    </template> -->
                        <th
                          v-for="(value, key) in projectsMap.zoneWiseData[
                            'leadgen'
                          ].googleAds"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </tr>
                      <tr v-else-if="businessTypeProject == 'leadform'">
                        <!-- <template v-if="index === 0">
                      <th>Date</th>
                    </template> -->
                        <th
                          v-for="(value, key) in projectsMap.zoneWiseData[
                            'leadform'
                          ].googleAds"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </tr>
                      <tr v-else>
                        <template v-if="index === 0">
                          <th>Date</th>
                        </template>
                        <th
                          v-for="(value, key) in projectsMap.zoneWiseData[
                            'default'
                          ].googleAds"
                          :key="key"
                        >
                          {{ value }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template
                        v-if="
                          selectedSource == 'all' || selectedSource == 'googleAds'
                        "
                      >
                        <tr
                          v-for="(data, dataIndex) in item.dateWiseData"
                          :key="dataIndex"
                        >
                          <template v-if="businessTypeProject == 'leadgen'">
                            <td
                              v-for="(value, key) in projectsMap.zoneWiseData[
                                'leadgen'
                              ].googleAds"
                              :key="key"
                            >
                              <!-- <span v-if="data.date == 'total'"><b>{{ data.insights[key] }}</b></span>
                             <span v-else>{{ data.insights[key] }}</span> -->
                              <span v-if="data.date == 'total'"
                                ><b
                                  >{{ data.insights[key]
                                  }}</b
                              ></span>
                              <span  v-else-if="key === 'actions'"  v-b-tooltip.hover.bottom:title="isDisabledActions? 'Select Google Ads or Meta to enable the button.' :'' ">
                                <span
                              :style="{ cursor: isDisabledActions || item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? 'not-allowed' : 'pointer', pointerEvents: isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall'? 'none' : 'auto' }"
                          >
                              <b-icon
                                  @click="openTargetVsAchievedVal(item.groupName, data.date)"
                                  class="upDownIcon"
                                  font-scale="1.5"
                                  icon="pencil-square"
                                  :color="isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? '#C7C8CC' :'#200E6B'"
                              />
                          </span>
                              </span>
                          
                              <span v-else>{{ data.insights[key] }}</span>
                            </td>
                          </template>
                          <template v-if="businessTypeProject == 'leadform'">
                            <td
                              v-for="(value, key) in projectsMap.zoneWiseData[
                                'leadform'
                              ].googleAds"
                              :key="key"
                            >
                              <!-- <span v-if="data.date == 'total'"><b>{{ data.insights[key] }}</b></span>
                             <span v-else>{{ data.insights[key] }}</span> -->
                              <span v-if="data.date == 'total'"
                                ><b
                                  >{{ data.insights[key]
                                  }}</b
                              ></span>
                              <span  v-else-if="key === 'actions'"  v-b-tooltip.hover.bottom:title="isDisabledActions? 'Select Google Ads or Meta to enable the button.' :'' ">
                                <span
                              :style="{ cursor: isDisabledActions || item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? 'not-allowed' : 'pointer', pointerEvents: isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall'? 'none' : 'auto' }"
                          >
                              <b-icon
                                  @click="openTargetVsAchievedVal(item.groupName, data.date)"
                                  class="upDownIcon"
                                  font-scale="1.5"
                                  icon="pencil-square"
                                  :color="isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? '#C7C8CC' :'#200E6B'"
                              />
                          </span>
                              </span>
                          
                              <span v-else>{{ data.insights[key] }}</span>
                            </td>
                          </template>
                          <template v-else>
                            <template v-if="index === 0">
                              <td
                                class="padding0"
                                style="color: gray ; font-size: 12px;"
                              >
                                {{ data.date }}
                              </td>
                            </template>
                            <td
                              v-for="(value, key) in projectsMap.zoneWiseData[
                                'default'
                              ].meta"
                              :key="key"
                            >
                              {{ data.insights[key] }}
                            </td>
                          </template>
                        </tr>
                      </template>
                      <template v-if="selectedSource == 'meta'">
                        <tr
                          v-for="(data, dataIndex) in item.dateWiseData"
                          :key="dataIndex"
                        >
                          <template v-if="businessTypeProject == 'leadgen'">
                            <td
                            v-for="(value, key) in projectsMap.zoneWiseData[
                              'leadgen'
                            ].meta"
                            :key="key"
                          >
                            <!-- <span v-if="data.date == 'total'"><b>{{ data.insights[key] }}</b></span>
                           <span v-else>{{ data.insights[key] }}</span> -->
                            <span v-if="data.date == 'total'"
                              ><b
                                >{{ data.insights[key]
                                }}</b
                            ></span>
                            <span
                            :style="{ cursor: isDisabledActions || item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? 'not-allowed' : 'pointer', pointerEvents: isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall'? 'none' : 'auto' }"
                            v-else-if="key === 'actions'"
                        >
                            <b-icon
                                @click="openTargetVsAchievedVal(item.groupName, data.date)"
                                class="upDownIcon"
                                font-scale="1.5"
                                icon="pencil-square"
                                 :color="isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? '#C7C8CC' :'#200E6B'"
                            />
                        </span>
                            <span v-else>{{ data.insights[key] }}</span>
                          </td>
                          </template>
                          <template v-else-if="businessTypeProject == 'leadform'">
                            <td
                            v-for="(value, key) in projectsMap.zoneWiseData[
                              'leadform'
                            ].meta"
                            :key="key"
                          >
                            <!-- <span v-if="data.date == 'total'"><b>{{ data.insights[key] }}</b></span>
                           <span v-else>{{ data.insights[key] }}</span> -->
                            <span v-if="data.date == 'total'"
                              ><b
                                >{{ data.insights[key]
                                }}</b
                            ></span>
                            <span
                            :style="{ cursor: isDisabledActions || item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? 'not-allowed' : 'pointer', pointerEvents: isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall'? 'none' : 'auto' }"
                            v-else-if="key === 'actions'"
                        >
                            <b-icon
                                @click="openTargetVsAchievedVal(item.groupName, data.date)"
                                class="upDownIcon"
                                font-scale="1.5"
                                icon="pencil-square"
                                 :color="isDisabledActions ||  item.groupName === 'All Zones' || item.groupName === 'ROTN-overall' ? '#C7C8CC' :'#200E6B'"
                            />
                        </span>
                            <span v-else>{{ data.insights[key] }}</span>
                          </td>
                          </template>
                          <template v-else>
                            <template v-if="index === 0">
                              <td
                                class="padding0"
                                style="color: gray ; font-size: 12px;"
                              >
                                {{ data.date }}
                              </td>
                            </template>
                            <td
                              v-for="(value, key) in projectsMap.zoneWiseData[
                                'default'
                              ].meta"
                              :key="key"
                            >
                              {{ data.insights[key] }}
                            </td>
                          </template>
                        </tr>
                      </template>
                    </tbody>
                  </template>
              
                </table>
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div v-if="this.type == 'zoneMetaWise'">
            <div class="spinnerCentered" v-if="zoneWiseDataLoading">
              <multiChannelSpinner />
            </div>
            <div
              class="spinnerCentered p-2"
              v-else-if="filteredZonewiseTableData.length === 0"
            >
              Data Not Found.
            </div>
            <div v-else class="tables-meta-container">
              <div class="date-column">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <feather-icon
                          icon="DownloadIcon"
                          color="#200E6B"
                          size="18"
                          style="cursor: pointer; margin-top:5px"
                          @click="downReportEntirely('Zonewize_Insights')"
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in filteredZonewiseTableData &&
                        filteredZonewiseTableData[0] &&
                        filteredZonewiseTableData[0].dateWiseData"
                      :key="index"
                    >
                      <td class="dateFacebookProjectTd paddingMeta0">
                        <span v-if="item.date == 'total'"
                          ><b>{{ item.date }}</b></span
                        >
                        <span v-else> {{ item.date }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-for="(item, index) in filteredZonewiseTableData"
                :key="index"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        colspan="10"
                        class="mainTh"
                        :style="{
                          backgroundColor: getProjectsBackgroundColor(index),
                        }"
                      >
                        <span
                          style="display: flex; justify-content: space-between; align-items: center;"
                        >
                          <span></span>
                          <span style="margin-top: 10px; float: right;">{{
                            item.groupName
                          }}</span>
                          <span style="float: right;"
                            ><feather-icon
                              icon="DownloadIcon"
                              color="#200E6B"
                              size="18"
                              style=" cursor: pointer;"
                              @click="
                                exportData(item.dateWiseData, item.groupName)
                              "
                          /></span>
                        </span>
                      </th>
                    </tr>
                    <tr v-if="businessTypeProject == 'leadgen'">
                      <!-- <template v-if="index === 0">
                    <th>Date</th>
                  </template> -->
                      <th
                        v-for="(value, key) in projectsMap.metaZoneWiseData[
                          'leadgen'
                        ].meta"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else-if="businessTypeProject == 'leadform'">
                      <!-- <template v-if="index === 0">
                    <th>Date</th>
                  </template> -->
                      <th
                        v-for="(value, key) in projectsMap.metaZoneWiseData[
                          'leadform'
                        ].meta"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else>
                      <template v-if="index === 0">
                        <th>Date</th>
                      </template>
                      <th
                        v-for="(value, key) in projectsMap.metaZoneWiseData[
                          'default'
                        ].meta"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template>
                      <tr
                        v-for="(data, dataIndex) in item.dateWiseData"
                        :key="dataIndex"
                      >
                        <template v-if="businessTypeProject == 'leadgen'">
                          <td
                            v-for="(value, key) in projectsMap.metaZoneWiseData[
                              'leadgen'
                            ].meta"
                            :key="key"
                          >
                            <span v-if="data.date == 'total'"
                              ><b>{{ data.insights[key] }}</b></span
                            ><span v-else>{{ data.insights[key] }}</span>
                          </td>
                        </template>
                        <template v-else-if="businessTypeProject == 'leadform'">
                          <td
                            v-for="(value, key) in projectsMap.metaZoneWiseData[
                              'leadform'
                            ].meta"
                            :key="key"
                          >
                            <span v-if="data.date == 'total'"
                              ><b>{{ data.insights[key] }}</b></span
                            ><span v-else>{{ data.insights[key] }}</span>
                          </td>
                        </template>
                        <template v-else>
                          <template v-if="index === 0">
                            <td
                              class="padding0"
                              style="color: gray ; font-size: 12px;"
                            >
                              {{ data.date }}
                            </td>
                          </template>
                          <td
                            v-for="(value, key) in projectsMap.metaZoneWiseData[
                              'default'
                            ].meta"
                            :key="key"
                          >
                            {{ data.insights[key] }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="this.type == 'zoneGoogleAdsWise'">
            <div class="spinnerCentered" v-if="zoneWiseDataLoading">
              <multiChannelSpinner />
            </div>
            <div
              class="spinnerCentered p-2"
              v-else-if="filteredZonewiseTableData.length === 0"
            >
              Data Not Found.
            </div>
            <div v-else class="tables-meta-container">
              <div class="date-column">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <feather-icon
                          icon="DownloadIcon"
                          color="#200E6B"
                          size="18"
                          style="cursor: pointer; margin-top:5px"
                          @click="downReportEntirely('Zonewize_Insights')"
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in filteredZonewiseTableData &&
                        filteredZonewiseTableData[0] &&
                        filteredZonewiseTableData[0].dateWiseData"
                      :key="index"
                    >
                      <td class="dateFacebookProjectTd paddingMeta0">
                        <span v-if="item.date == 'total'"
                          ><b>{{ item.date }}</b></span
                        >
                        <span v-else> {{ item.date }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-for="(item, index) in filteredZonewiseTableData"
                :key="index"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        colspan="10"
                        class="mainTh"
                        :style="{
                          backgroundColor: getProjectsBackgroundColor(index),
                        }"
                      >
                        <span
                          style="display: flex; justify-content: space-between; align-items: center;"
                        >
                          <span></span>
                          <span style="margin-top: 10px; float: right;">{{
                            item.groupName
                          }}</span>
                          <span style="float: right;"
                            ><feather-icon
                              icon="DownloadIcon"
                              color="#200E6B"
                              size="18"
                              style=" cursor: pointer;"
                              @click="
                                exportData(item.dateWiseData, item.groupName)
                              "
                          /></span>
                        </span>
                      </th>
                    </tr>
                    <tr v-if="businessTypeProject == 'leadgen'">
                      <th
                        v-for="(value, key) in projectsMap.zoneGoogleWiseData[
                          'leadgen'
                        ].googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else-if="businessTypeProject == 'leadform'">
                      <th
                        v-for="(value, key) in projectsMap.zoneGoogleWiseData[
                          'leadform'
                        ].googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else>
                      <template v-if="index === 0">
                        <th>Date</th>
                      </template>
                      <th
                        v-for="(value, key) in projectsMap.zoneGoogleWiseData[
                          'default'
                        ].googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template>
                      <tr
                        v-for="(data, dataIndex) in item.dateWiseData"
                        :key="dataIndex"
                      >
                        <template v-if="businessTypeProject == 'leadgen'">
                          <td
                            v-for="(value, key) in projectsMap.zoneGoogleWiseData[
                              'leadgen'
                            ].googleAds"
                            :key="key"
                          >
                            <span v-if="data.date == 'total'"
                              ><b>{{ data.insights[key] }}</b></span
                            ><span v-else>{{ data.insights[key] }}</span>
                          </td>
                        </template>
                        <template v-else-if="businessTypeProject == 'leadform'">
                          <td
                            v-for="(value, key) in projectsMap.zoneGoogleWiseData[
                              'leadform'
                            ].googleAds"
                            :key="key"
                          >
                            <span v-if="data.date == 'total'"
                              ><b>{{ data.insights[key] }}</b></span
                            ><span v-else>{{ data.insights[key] }}</span>
                          </td>
                        </template>
                        <template v-else>
                          <template v-if="index === 0">
                            <td
                              class="padding0"
                              style="color: gray ; font-size: 12px;"
                            >
                              {{ data.date }}
                            </td>
                          </template>
                          <td
                            v-for="(value, key) in projectsMap.zoneGoogleWiseData[
                              'default'
                            ].googleAds"
                            :key="key"
                          >
                            {{ data.insights[key] }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="this.type == 'productGoogleAdsWise'">
            <div class="spinnerCentered" v-if="productWiseDataLoading">
              <multiChannelSpinner />
            </div>
            <div
              class="spinnerCentered"
              v-else-if="
                Object.values(filteredProductwiseTableData).length === 0
              "
            >
              Data Not Found.
            </div>
            <div v-else class="tables-meta-container">
              <div class="date-column">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <feather-icon
                          icon="DownloadIcon"
                          color="#200E6B"
                          size="18"
                          style="cursor: pointer; margin-top:5px"
                          @click="downReportProductEntirely('Product')"
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in filteredProductwiseTableData &&
                        filteredProductwiseTableData[0].dateWiseData"
                      :key="index"
                    >
                      <td class="dateFacebookProjectTd paddingMeta0">
                        <span v-if="item.date == 'total'"
                          ><b>{{ item.date }}</b></span
                        >
                        <span v-else> {{ item.date }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-for="(item, index) in filteredProductwiseTableData"
                :key="index"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        colspan="10"
                        class="mainTh"
                        :style="{
                          backgroundColor: getProjectsBackgroundColor(index),
                        }"
                      >
                        <span
                          style="display: flex; justify-content: space-between; align-items: center;"
                        >
                          <span></span>
                          <span style="margin-top: 10px; float: right;">{{
                            item.productOptionName
                          }}</span>
                          <span style="float: right;"
                            ><feather-icon
                              icon="DownloadIcon"
                              color="#200E6B"
                              size="18"
                              style=" cursor: pointer;"
                              @click="
                                exportData(
                                  item.dateWiseData,
                                  item.productOptionName
                                )
                              "
                          /></span>
                        </span>
                      </th>
                    </tr>
                    <tr v-if="businessTypeProject == 'leadgen'">
                      <th
                        v-for="(value, key) in projectsMap.productWiseData[
                          'leadgen'
                        ].googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else-if="businessTypeProject == 'leadform'">
                      <th
                        v-for="(value, key) in projectsMap.productWiseData[
                          'leadform'
                        ].googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else>
                      <template v-if="index === 0">
                        <th>Date</th>
                      </template>
                      <th
                        v-for="(value, key) in projectsMap.productWiseData[
                          'default'
                        ].googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template>
                      <tr
                        v-for="(data, dataIndex) in item.dateWiseData"
                        :key="dataIndex"
                      >
                        <template v-if="businessTypeProject == 'leadgen'">
                          <td
                            v-for="(value, key) in projectsMap.productWiseData[
                              'leadgen'
                            ].googleAds"
                            :key="key"
                          >
                            <span
                              v-if="
                                data.date == 'total' || data.date == 'Total'
                              "
                              ><b>{{ data.insights[key] }}</b></span
                            ><span v-else>{{ data.insights[key] }}</span>
                          </td>
                        </template>
                        <template v-else-if="businessTypeProject == 'leadform'">
                          <td
                            v-for="(value, key) in projectsMap.productWiseData[
                              'leadform'
                            ].googleAds"
                            :key="key"
                          >
                            <span
                              v-if="
                                data.date == 'total' || data.date == 'Total'
                              "
                              ><b>{{ data.insights[key] }}</b></span
                            ><span v-else>{{ data.insights[key] }}</span>
                          </td>
                        </template>
                        <template v-else>
                          <template v-if="index === 0">
                            <td
                              class="padding0"
                              style="color: gray ; font-size: 12px;"
                            >
                              {{ data.date }}
                            </td>
                          </template>
                          <td
                            v-for="(value, key) in projectsMap.productWiseData[
                              'default'
                            ].googleAds"
                            :key="key"
                          >
                            {{ data.insights[key] }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="this.type == 'productMetaWise'">
            <div class="spinnerCentered" v-if="productWiseDataLoading">
              <multiChannelSpinner />
            </div>
            <div
              class="spinnerCentered p-2"
              v-else-if="
                Object.values(filteredProductwiseTableData).length === 0
              "
            >
              Data Not Found.
            </div>
            <div v-else class="tables-meta-container">
              <div class="date-column">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <feather-icon
                          icon="DownloadIcon"
                          color="#200E6B"
                          size="18"
                          style="cursor: pointer; margin-top:5px"
                          @click="downReportProductEntirely('Product')"
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in filteredProductwiseTableData &&
                        filteredProductwiseTableData[0].dateWiseData"
                      :key="index"
                    >
                      <td class="dateFacebookProjectTd paddingMeta0">
                        <span v-if="item.date == 'total'"
                          ><b>{{ item.date }}</b></span
                        >
                        <span v-else> {{ item.date }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-for="(item, index) in filteredProductwiseTableData"
                :key="index"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        colspan="10"
                        class="mainTh"
                        :style="{
                          backgroundColor: getProjectsBackgroundColor(index),
                        }"
                      >
                        <span
                          style="display: flex; justify-content: space-between; align-items: center;"
                        >
                          <span></span>
                          <span style="margin-top: 10px; float: right;">{{
                            item.productOptionName
                          }}</span>
                          <span style="float: right;"
                            ><feather-icon
                              icon="DownloadIcon"
                              color="#200E6B"
                              size="18"
                              style=" cursor: pointer;"
                              @click="
                                exportData(
                                  item.dateWiseData,
                                  item.productOptionName
                                )
                              "
                          /></span>
                        </span>
                      </th>
                    </tr>
                    <tr v-if="businessTypeProject == 'leadgen'">
                      <th
                        v-for="(value, key) in projectsMap.productWiseData[
                          'leadgen'
                        ].googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else-if="businessTypeProject == 'leadform'">
                      <th
                        v-for="(value, key) in projectsMap.productWiseData[
                          'leadform'
                        ].googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else>
                      <template v-if="index === 0">
                        <th>Date</th>
                      </template>
                      <th
                        v-for="(value, key) in projectsMap.productWiseData[
                          'default'
                        ].googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template>
                      <tr
                        v-for="(data, dataIndex) in item.dateWiseData"
                        :key="dataIndex"
                      >
                        <template v-if="businessTypeProject == 'leadgen'">
                          <td
                            v-for="(value, key) in projectsMap.productWiseData[
                              'leadgen'
                            ].meta"
                            :key="key"
                          >
                            <span
                              v-if="
                                data.date == 'total' || data.date == 'Total'
                              "
                              ><b>{{ data.insights[key] }}</b></span
                            ><span v-else>{{ data.insights[key] }}</span>
                          </td>
                        </template>
                        <template v-else>
                          <template v-if="index === 0">
                            <td
                              class="padding0"
                              style="color: gray ; font-size: 12px;"
                            >
                              {{ data.date }}
                            </td>
                          </template>
                          <td
                            v-for="(value, key) in projectsMap.productWiseData[
                              'default'
                            ].meta"
                            :key="key"
                          >
                            {{ data.insights[key] }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="this.type == 'productWise'">
            <div class="spinnerCentered" v-if="productWiseDataLoading">
              <multiChannelSpinner />
            </div>
            <div
              class="spinnerCentered p-2"
              v-else-if="
                Object.values(filteredProductwiseTableData).length === 0
              "
            >
              Data Not Found.
            </div>
            <div v-else class="tables-meta-container">
              <div class="date-column">
                <table class="table">
                  <thead>
                    <tr>
                      <th>
                        <feather-icon
                          icon="DownloadIcon"
                          color="#200E6B"
                          size="18"
                          style="cursor: pointer; margin-top:5px"
                          @click="downReportProductEntirely('Product')"
                        />
                      </th>
                    </tr>
                    <tr>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in filteredProductwiseTableData &&
                        filteredProductwiseTableData[0].dateWiseData"
                      :key="index"
                    >
                      <td class="dateFacebookProjectTd paddingMeta0">
                        <span v-if="item.date == 'total'"
                          ><b>{{ item.date }}</b></span
                        >
                        <span v-else> {{ item.date }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-for="(item, index) in filteredProductwiseTableData"
                :key="index"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th
                       :colspan="salesforceDataFound ? '14' : '10'"
                        class="mainTh"
                        :style="{
                          backgroundColor: getProjectsBackgroundColor(index),
                        }"
                      >
                        <span
                          style="display: flex; justify-content: space-between; align-items: center;"
                        >
                          <span></span>
                          <span style="margin-top: 10px; float: right;">{{
                            item.productOptionName
                          }}</span>
                          <span style="float: right;"
                            ><feather-icon
                              icon="DownloadIcon"
                              color="#200E6B"
                              size="18"
                              style=" cursor: pointer;"
                              @click="
                                exportData(
                                  item.dateWiseData,
                                  item.productOptionName
                                )
                              "
                          /></span>
                        </span>
                      </th>
                    </tr>
                    <tr v-if="businessTypeProject == 'leadgen' && salesforceDataFound">
                      <th
                        v-for="(value, key) in projectsMap.productWiseData[
                          'leadgen'
                        ].withLeadSalesforce"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else-if="businessTypeProject == 'leadgen'">
                      <th
                        v-for="(value, key) in projectsMap.productWiseData[
                          'leadgen'
                        ].googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else-if="businessTypeProject == 'leadform' && salesforceDataFound">
                      <th
                        v-for="(value, key) in projectsMap.productWiseData[
                          'leadform'
                        ].withLeadSalesforce"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else-if="businessTypeProject == 'leadform'">
                      <th
                        v-for="(value, key) in projectsMap.productWiseData[
                          'leadform'
                        ].googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                    <tr v-else>
                      <template v-if="index === 0">
                        <th>Date</th>
                      </template>
                      <th
                        v-for="(value, key) in projectsMap.productWiseData[
                          'default'
                        ].googleAds"
                        :key="key"
                      >
                        {{ value }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template>
                      <tr
                        v-for="(data, dataIndex) in item.dateWiseData"
                        :key="dataIndex"
                      >  
                      <template v-if="businessTypeProject == 'leadgen' && salesforceDataFound">
                        <td
                          v-for="(value, key) in projectsMap.productWiseData[
                            'leadgen'
                          ].withLeadSalesforce"
                          :key="key"
                        >
                          <span
                            v-if="
                              data.date == 'total' || data.date == 'Total'
                            "
                            ><b>
                              <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                                {{ data &&  data.insights &&  data.insights.salesforce && data.insights.salesforce[key] }}
                              </span>
                              <span v-else>
                                {{ data.insights[key] }}
                              </span>

                            </b></span
                          ><span v-else>
                            <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                              {{ data &&  data.insights &&  data.insights.salesforce && data.insights.salesforce[key] }}
                            </span>
                            <span v-else>
                              {{ data.insights[key] }}
                            </span>
                          </span>
                        </td>
                      </template>
                        <template v-else-if="businessTypeProject == 'leadgen'">
                          <td
                            v-for="(value, key) in projectsMap.productWiseData[
                              'leadgen'
                            ].googleAds"
                            :key="key"
                          >
                            <span
                              v-if="
                                data.date == 'total' || data.date == 'Total'
                              "
                              ><b>
                                {{ data.insights[key] }}

                              </b></span
                            ><span v-else>{{ data.insights[key] }}</span>
                          </td>
                        </template>
                        <template v-else-if="businessTypeProject == 'leadform' && salesforceDataFound">
                          <td
                            v-for="(value, key) in projectsMap.productWiseData[
                              'leadform'
                            ].withLeadSalesforce"
                            :key="key"
                          >
                            <span
                              v-if="
                                data.date == 'total' || data.date == 'Total'
                              "
                              ><b>
                                <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                                  {{ data &&  data.insights &&  data.insights.salesforce && data.insights.salesforce[key] }}
                                </span>
                                <span v-else>
                                  {{ data.insights[key] }}
                                </span>
  
                              </b></span
                            ><span v-else>
                              <span v-if="key === 'sales' || key === 'qualityLeads' || key === 'costPerQualityLeads' || key === 'siteVisits'">
                                {{ data &&  data.insights &&  data.insights.salesforce && data.insights.salesforce[key] }}
                              </span>
                              <span v-else>
                                {{ data.insights[key] }}
                              </span>
                            </span>
                          </td>
                        </template>
                          <template v-else-if="businessTypeProject == 'leadform'">
                            <td
                              v-for="(value, key) in projectsMap.productWiseData[
                                'leadform'
                              ].googleAds"
                              :key="key"
                            >
                              <span
                                v-if="
                                  data.date == 'total' || data.date == 'Total'
                                "
                                ><b>
                                  {{ data.insights[key] }}
  
                                </b></span
                              ><span v-else>{{ data.insights[key] }}</span>
                            </td>
                          </template>
                        <template v-else>
                          <template v-if="index === 0">
                            <td
                              class="padding0"
                              style="color: gray ; font-size: 12px;"
                            >
                              {{ data.date }}
                            </td>
                          </template>
                          <td
                            v-for="(value, key) in projectsMap.productWiseData[
                              'default'
                            ].meta"
                            :key="key"
                          >
                            {{ data.insights[key] }}
                          </td>
                        </template>
                      </tr>
                    </template>
                    <template v-if="selectedSource == 'meta'">
                      <tr
                        v-for="(data, dataIndex) in item.dateWiseData"
                        :key="dataIndex"
                      >
                        <template v-if="businessTypeProject == 'leadgen'">
                          <td
                            v-for="(value, key) in projectsMap.productWiseData[
                              'leadgen'
                            ].meta"
                            :key="key"
                          >
                            <span v-if="data.date == 'total'"
                              ><b>{{ data.insights[key] }}</b></span
                            ><span v-else>{{ data.insights[key] }}</span>
                          </td>
                        </template>
                        <template v-else>
                          <template v-if="index === 0">
                            <td
                              class="padding0"
                              style="color: gray ; font-size: 12px;"
                            >
                              {{ data.date }}
                            </td>
                          </template>
                          <td
                            v-for="(value, key) in projectsMap.productWiseData[
                              'default'
                            ].meta"
                            :key="key"
                          >
                            {{ data.insights[key] }}
                          </td>
                        </template>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  
  </div>
</template>

<script>
import projectsMap from "@/@core/utils/projectDetailMap";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import {
  BCard,
  BCardTitle,
  BCol,
  BFormSelect,
  BRow,
  BModal,
  BCardText,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  data() {
    return {
      addDataToZonewiseModal: false,
      filterDataZoneWiseOverallType: [],
      filterDataZoneWiseGoogleType: [],
      filterDataZoneWiseMetaType: [],
      subProjectSelections: "all",
      displayZoneWiseSelected: [],
      sourceProjectsType: [],
      sourceProjectsOverallType: [],
      sourceProjectsGoogleType: [],
      sourceProjectsMetaType: [],
      selectedZoneType: "all",
      selectedSource: "all",
      selectedProductSource: "all",
      localSelectBoxType: this.selectedSource,
      sourceType: ["all", "googleAds", "meta"],
      filterDataProjectSelection: [],
      filterDataZoneWiseType: "All Zones",
      filterMetaDataZoneWiseType: "All Zones",
      filterGoogleDataZoneWiseType: "All Zones",
      filterDataProjectWiseSelection: [],
      filterDataProductSelection: [],
      filterDataProductProjectsSelection: [],
      projectsMap: projectsMap,
      items: [],
      selectedAdsOption: "All",
      lightColorHashCodes: [
        "#AFEEEE", // Light Turquoise
        "#E6E6FA", // Light Lavender
        "#F08080", // Light Coral
        "#778899", // Light Slate Gray
        "#9370DB", // Light Indigo
        "#B03060", // Light Maroon
        "#008B8B", // Light Teal
        "#808000", // Light Olive
        "#FFA07A", // Light Salmon
        "#D8BFD8", // Light Orchid
      ],
    };
  },
  watch: {
    selectedSource(newVal) {
      this.localSelectBoxType = newVal;
    },
    filterOption() {
      this.selectedAdsOption = "All";
    },
    filterDataZoneWiseType(newVal, oldVal) {
      this.sourceProjectsType = [];
      this.displayZoneWiseSelected = [];
      this.filterDataZoneWiseType = newVal;
    },
  },
  methods: {
    getLastPartAfterHyphen(str) {
    const lastHyphenIndex = str.lastIndexOf('-');
    if (lastHyphenIndex !== -1) {
        return str.substring(lastHyphenIndex + 1).trim();
    }
    return str;
   },
    CloseDataToZonewiseModal() {
      this.addDataToZonewiseModal = false;
    },
    openDataToZonewiseModal() {
      this.addDataToZonewiseModal = true;
    },
    convertMonthDate(dateString) {
      const dateParts = dateString.split("-");
      const day = dateParts[0];
      const month = dateParts[1];
      const year = dateParts[2];

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const monthName = months[parseInt(month) - 1];
      const shortYear = year.slice(-2);

      return `${monthName}-${shortYear}`;
    },
    openTargetVsAchievedVal(value, date){
      let result = this.getLastPartAfterHyphen(value)
      this.$emit("openModal", result, date)
    },
    handleMetricsChange(newSelection) {
      this.$emit("sourceChangedData", newSelection);
    },
    handleProductMetricsChange(newSelection) {
      this.$emit("sourceProductChangedData", newSelection);
    },
    handleZoneWiseChange(newSelection) {
      this.$emit("zoneTypeChangedData", newSelection);
    },
    handleSubOptionsChange(newSelection) {
      this.$emit("getSubProductOptions", newSelection);
    },
    handleFilterDataZoneWiseType(newSelection) {
      this.$emit("filterDataZoneWiseType", newSelection);
    },
    getProjectsBackgroundColor(index) {
      return this.lightColorHashCodes[index % 10] + "!important";
    },
    handleZoneListChange(newSelection) {
      this.displayZoneWiseSelected = newSelection;
      this.$emit("handleZoneListChange", newSelection);
    },
    handleZoneListProjectChange(newSelection) {
      this.$emit("handleZoneListProjectChange", newSelection);
    },
    removeINR(text) {
      // Define the regular expression pattern to match "INR" followed by digits
      var pattern = /INR\s*(\d{1,3}(?:,\d{3})*)(?:\.\d+)?/g;

      // Use replace method to remove "INR" and retain the digits
      var result = text.replace(pattern, "$1");

      return result;
    },
    downReportEntirely(name) {
      // Define the display array based on the ad type
      let displayArr = {};
      if (this.channelType === "meta") {
        displayArr = {
          Date: "date",
          Spends: "insights.spend",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
        };
      } else if (
        this.channelType === "google" ||
        this.channelType === "overall"
      ) {
        displayArr = {
          Date: "date",
          Spends: "insights.costMicros",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
        };
      } else {
        displayArr = {
          Date: "date",
          Spends: "insights.spend",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
        };
      }

      // Function to get nested values from objects using a string path
      const getNestedValue = (obj, path) => {
        return path.split(".").reduce((acc, part) => acc && acc[part], obj);
      };

      // Check if data is an array and not empty
      if (!Array.isArray(this.tableData) || this.tableData.length === 0) {
        console.error("Data is empty or not an array");
        return;
      }

      // Function to escape CSV values
      const escapeCSV = (str) => {
        if (str === null || str === undefined) {
          return '""';
        }
        return `"${String(str).replace(/"/g, '""')}"`;
      };

      // Create CSV headers
      const headers = ["Date"];
      const groupNames = [];

      // Collect group names and headers
      this.tableData.forEach((item) => {
        Object.keys(displayArr).forEach((key) => {
          if (key !== "Date") {
            headers.push(`${key}`);
            groupNames.push(item.groupName);
          }
        });
      });

      // Create group names header row
      const groupHeaderRow = ["Group Name", ...groupNames]
        .map((header) => escapeCSV(header))
        .join(",");

      // Create main header row
      const headerRow = headers.map((header) => escapeCSV(header)).join(",");

      // Get all unique dates
      const allDates = new Set();
      this.tableData.forEach((item) => {
        item.dateWiseData.forEach((dateData) => {
          allDates.add(dateData.date);
        });
      });

      // Create CSV rows for each unique date
      const rows = Array.from(allDates).map((date) => {
        const row = [escapeCSV(date)];
        this.tableData.forEach((item) => {
          const dateData = item.dateWiseData.find((d) => d.date === date) || {};
          Object.keys(displayArr).forEach((key) => {
            if (key !== "Date") {
              row.push(escapeCSV(getNestedValue(dateData, displayArr[key])));
            }
          });
        });
        return row.join(",");
      });

      // Combine group names header, main header, and rows to form CSV content
      const csv = [groupHeaderRow, headerRow, ...rows].join("\n");
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      // Create a download link for the CSV file
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `${this.clientName}_${name}_${Date.now()}.csv`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    downReportProjectsEntirely(name) {
      // Define the display array based on the ad type
      let displayArr = {};
      if (this.channelType === "meta") {
        displayArr = {
          Date: "date",
          Spends: "insights.spend",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
        };
      } else if (
        this.channelType === "google" ||
        this.channelType === "overall"
      ) {
        displayArr = {
          Date: "date",
          Spends: "insights.costMicros",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
        };
      } else {
        displayArr = {
          Date: "date",
          Spends: "insights.spend",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
        };
      }

      // Function to get nested values from objects using a string path
      const getNestedValue = (obj, path) => {
        return path.split(".").reduce((acc, part) => acc && acc[part], obj);
      };

      // Check if data is an array and not empty
      if (!Array.isArray(this.tableData) || this.tableData.length === 0) {
        console.error("Data is empty or not an array");
        return;
      }

      // Function to escape CSV values
      const escapeCSV = (str) => {
        if (str === null || str === undefined) {
          return '""';
        }
        return `"${String(str).replace(/"/g, '""')}"`;
      };

      // Create CSV headers
      const headers = ["Date"];
      const groupNames = [];

      // Collect group names and headers
      this.tableData.forEach((item) => {
        Object.keys(displayArr).forEach((key) => {
          if (key !== "Date") {
            headers.push(`${key}`);
            groupNames.push(item.projectName);
          }
        });
      });

      // Create group names header row
      const groupHeaderRow = ["Group Name", ...groupNames]
        .map((header) => escapeCSV(header))
        .join(",");

      // Create main header row
      const headerRow = headers.map((header) => escapeCSV(header)).join(",");

      // Get all unique dates
      const allDates = new Set();
      this.tableData.forEach((item) => {
        item.dateWiseData.forEach((dateData) => {
          allDates.add(dateData.date);
        });
      });

      // Create CSV rows for each unique date
      const rows = Array.from(allDates).map((date) => {
        const row = [escapeCSV(date)];
        this.tableData.forEach((item) => {
          const dateData = item.dateWiseData.find((d) => d.date === date) || {};
          Object.keys(displayArr).forEach((key) => {
            if (key !== "Date") {
              row.push(escapeCSV(getNestedValue(dateData, displayArr[key])));
            }
          });
        });
        return row.join(",");
      });

      // Combine group names header, main header, and rows to form CSV content
      const csv = [groupHeaderRow, headerRow, ...rows].join("\n");
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      // Create a download link for the CSV file
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `${this.clientName}_${name}_${Date.now()}.csv`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    downReportProductEntirely(name) {
      // Define the display array based on the ad type
      let displayArr = {};
      if (this.channelType === "meta") {
        displayArr = {
          Date: "date",
          Spends: "insights.spend",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
        };
      } else if (
        this.channelType === "google" ||
        this.channelType === "overall"
      ) {
        displayArr = {
          Date: "date",
          Spends: "insights.costMicros",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
        };
      } else {
        displayArr = {
          Date: "date",
          Spends: "insights.spend",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
        };
      }

      // Function to get nested values from objects using a string path
      const getNestedValue = (obj, path) => {
        return path.split(".").reduce((acc, part) => acc && acc[part], obj);
      };

      // Check if data is an array and not empty
      if (!Array.isArray(this.tableData) || this.tableData.length === 0) {
        console.error("Data is empty or not an array");
        return;
      }

      // Function to escape CSV values
      const escapeCSV = (str) => {
        if (str === null || str === undefined) {
          return '""';
        }
        return `"${String(str).replace(/"/g, '""')}"`;
      };

      // Create CSV headers
      const headers = ["Date"];
      const groupNames = [];

      // Collect group names and headers
      this.tableData.forEach((item) => {
        Object.keys(displayArr).forEach((key) => {
          if (key !== "Date") {
            headers.push(`${key}`);
            groupNames.push(item.productOptionName);
          }
        });
      });

      // Create group names header row
      const groupHeaderRow = ["Group Name", ...groupNames]
        .map((header) => escapeCSV(header))
        .join(",");

      // Create main header row
      const headerRow = headers.map((header) => escapeCSV(header)).join(",");

      // Get all unique dates
      const allDates = new Set();
      this.tableData.forEach((item) => {
        item.dateWiseData.forEach((dateData) => {
          allDates.add(dateData.date);
        });
      });

      // Create CSV rows for each unique date
      const rows = Array.from(allDates).map((date) => {
        const row = [escapeCSV(date)];
        this.tableData.forEach((item) => {
          const dateData = item.dateWiseData.find((d) => d.date === date) || {};
          Object.keys(displayArr).forEach((key) => {
            if (key !== "Date") {
              row.push(escapeCSV(getNestedValue(dateData, displayArr[key])));
            }
          });
        });
        return row.join(",");
      });

      // Combine group names header, main header, and rows to form CSV content
      const csv = [groupHeaderRow, headerRow, ...rows].join("\n");
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      // Create a download link for the CSV file
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `${this.clientName}_${name}_${Date.now()}.csv`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    exportData(data, name) {
      // Define the display array based on the ad type
      let displayArr = {};
      if (this.channelType == "meta") {
        displayArr = {
          Date: "date",
          Spends: "insights.spend",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
          // Revenue: "insights.conversionsValue",
          // ROAS: "insights.ROAS",
        };
      } else if (this.channelType == "google") {
        displayArr = {
          Date: "date",
          Spends: "insights.costMicros",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
          // Revenue: "insights.conversionsValue",
          // ROAS: "insights.ROAS",
        };
      } else if (this.channelType == "overall") {
        displayArr = {
          Date: "date",
          Spends: "insights.costMicros",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
          // Revenue: "insights.conversionsValue",
          // ROAS: "insights.ROAS",
        };
      } else {
        displayArr = {
          Date: "date",
          Spends: "insights.spend",
          Impressions: "insights.impressions",
          CPM: "insights.cpm",
          Clicks: "insights.clicks",
          CPC: "insights.cpc",
          CTR: "insights.ctr",
          Conversions: "insights.conversions",
          CPL: "insights.costPerConversion",
          // Revenue: "insights.conversionsValue",
          // ROAS: "insights.ROAS",
        };
      }

      // Check if data is an array and not empty
      if (!Array.isArray(data) || data.length === 0) {
        console.error("Data is empty or not an array");
        return;
      }

      // Function to escape CSV values
      const escapeCSV = (str) => {
        if (str === null || str === undefined) {
          return '""';
        }
        return `"${String(str).replace(/"/g, '""')}"`;
      };

      // Function to get nested values from objects using a string path
      const getNestedValue = (obj, path) => {
        return path.split(".").reduce((acc, part) => acc && acc[part], obj);
      };

      // Create CSV header
      const header = Object.keys(displayArr)
        .map((displayName) =>
          escapeCSV(displayName.charAt(0).toUpperCase() + displayName.slice(1))
        )
        .join(",");

      // Create CSV rows
      const rows = [header].concat(
        data.map((item) => {
          return Object.keys(displayArr)
            .map((key) => escapeCSV(getNestedValue(item, displayArr[key])))
            .join(",");
        })
      );

      // Combine header and rows to form CSV content
      const csv = rows.join("\n");
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      // Create a download link for the CSV file
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${name}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
  computed: {
    isDisabledActions() {
        return this.selectedSource !== 'googleAds' && this.selectedSource !== 'meta';
    },
    mergedSubProjectData() {
      let updateList = [];
      updateList = ["all", ...this.zoneWiseType.subProductOptions.LeadGen];
      return updateList;
    },
    isSelectDisabled() {
      return (
        this.filterDataZoneWiseType === "All Zones" ||
        this.filterDataZoneWiseType === "defaultOne"
      );
    },
    tooltipMessage() {
      return this.isSelectDisabled
        ? "Please Select a Zone to Enable the Project"
        : "";
    },
    tooltipZoneWiseSelect() {
      return this.filterDataZoneWiseOverallType.length === 0
        ? "Please Select a Zone to Enable the Project"
        : "";
    },
    tooltipMetaZoneWiseSelect() {
      return this.filterDataZoneWiseMetaType.length === 0
        ? "Please Select a Zone to Enable the Project"
        : "";
    },
    tooltipGoogleZoneWiseSelect() {
      return this.filterDataZoneWiseGoogleType.length === 0
        ? "Please Select a Zone to Enable the Project"
        : "";
    },
    tooltipZoneWiseOverallMessage() {
      return this.filterDataZoneWiseOverallType.length === 0
        ? "Please select a zone first to proceed with selecting a project."
        : "";
    },
    tooltipZoneWiseMetaMessage() {
      return this.filterDataZoneWiseMetaType.length === 0
        ? "Please select a zone first to proceed with selecting a project."
        : "";
    },
    tooltipZoneWiseGoogleMessage() {
      return this.filterDataZoneWiseGoogleType.length === 0
        ? "Please select a zone first to proceed with selecting a project."
        : "";
    },
    mergedZoneWiseData() {
      let updateList = [];
      if (this.selectedSource === "all") {
        updateList = [
          "all",
          ...this.zoneWiseType.products.googleAds,
          ...this.zoneWiseType.products.meta,
        ];
        this.selectedZoneType = updateList[0];
        return updateList;
      } else if (this.selectedSource === "googleAds") {
        updateList = ["all", ...this.zoneWiseType.products.googleAds];
        this.selectedZoneType = updateList[0];
        return updateList;
      } else if (this.selectedSource === "meta") {
        updateList = ["all", ...this.zoneWiseType.products.meta];
        this.selectedZoneType = updateList[0];
        return updateList;
      } else {
        return [];
      }
    },
    mergedProjectWiseData() {
      let mergedData = [];
      if (this.filterDataZoneWiseType === "All Zones") {
        const combinedArray = Object.values(
          this.zoneWiseType.projectNames
        ).flat();
        this.creativeOptions = [...combinedArray];
        mergedData = [...combinedArray];
      } else {
        if (this.zoneWiseType.projectNames[this.filterDataZoneWiseType]) {
          mergedData = [
            ...this.zoneWiseType.projectNames[this.filterDataZoneWiseType],
          ];
        }
      }
      return mergedData;
    },
    mergeAllProjectNames() {
      // this.filterDataProductProjectsSelection = [...this.productAllProjectWiseType]
      return this.productAllProjectWiseType;
    },
    mergedProjectWiseOverallData() {
      let mergedData = [];
      if (
        Array.isArray(this.filterDataZoneWiseOverallType) &&
        this.filterDataZoneWiseOverallType.length > 0
      ) {
        this.filterDataZoneWiseOverallType.forEach((zone) => {
          if (this.zoneWiseType.projectNames[zone]) {
            mergedData = mergedData.concat(
              this.zoneWiseType.projectNames[zone]
            );
          }
        });
        this.creativeOptions = [...mergedData];
      } else {
        if (
          this.zoneWiseType.projectNames[this.filterDataZoneWiseOverallType]
        ) {
          mergedData = [
            ...this.zoneWiseType.projectNames[
              this.filterDataZoneWiseOverallType
            ],
          ];
        }
      }
      // this.sourceProjectsOverallType = [...mergedData];
      this.sourceProjectsGoogleType = [...mergedData];
      this.sourceProjectsMetaType = [...mergedData];
      return mergedData;
    },
    mergedProjectWiseGoogleData() {
      let mergedData = [];
      if (
        Array.isArray(this.filterDataZoneWiseGoogleType) &&
        this.filterDataZoneWiseGoogleType.length > 0
      ) {
        this.filterDataZoneWiseGoogleType.forEach((zone) => {
          if (this.zoneWiseType.projectNames[zone]) {
            mergedData = mergedData.concat(
              this.zoneWiseType.projectNames[zone]
            );
          }
        });
        this.creativeOptions = [...mergedData];
      } else {
        if (this.zoneWiseType.projectNames[this.filterDataZoneWiseGoogleType]) {
          mergedData = [
            ...this.zoneWiseType.projectNames[
              this.filterDataZoneWiseGoogleType
            ],
          ];
        }
      }
      this.sourceProjectsOverallType = [...mergedData];
      this.sourceProjectsGoogleType = [...mergedData];
      this.sourceProjectsMetaType = [...mergedData];
      return mergedData;
    },
    mergedProjectWiseMetaData() {
      let mergedData = [];
      if (
        Array.isArray(this.filterDataZoneWiseMetaType) &&
        this.filterDataZoneWiseMetaType.length > 0
      ) {
        this.filterDataZoneWiseMetaType.forEach((zone) => {
          if (this.zoneWiseType.projectNames[zone]) {
            mergedData = mergedData.concat(
              this.zoneWiseType.projectNames[zone]
            );
          }
        });
        this.creativeOptions = [...mergedData];
      } else {
        if (this.zoneWiseType.projectNames[this.filterDataZoneWiseMetaType]) {
          mergedData = [
            ...this.zoneWiseType.projectNames[this.filterDataZoneWiseMetaType],
          ];
        }
      }
      this.sourceProjectsOverallType = [...mergedData];
      this.sourceProjectsMetaType = [...mergedData];
      return mergedData;
    },
    mergeZoneWiseOptionsList() {
      return this.zoneWiseType.groups;
    },
    mergeZoneWiseOverallOptionsList() {
      this.filterDataZoneWiseOverallType = [...this.zoneWiseType.groups];
      return this.zoneWiseType.groups;
    },
    mergeZoneWiseGoogleOptionsList() {
      this.filterDataZoneWiseGoogleType = [...this.zoneWiseType.groups];
      return this.zoneWiseType.groups;
    },
    mergeZoneWiseMetaOptionsList() {
      this.filterDataZoneWiseMetaType = [...this.zoneWiseType.groups];
      return this.zoneWiseType.groups;
    },
    mergedGoogleZoneWiseData() {
      let updateList = [];
      updateList = ["all", ...this.zoneWiseType.products.googleAds];
      this.selectedZoneType = updateList[0];
      return updateList;
    },
    mergedMetaZoneWiseData() {
      let updateList = [];
      updateList = ["all", ...this.zoneWiseType.products.meta];
      this.selectedZoneType = updateList[0];
      return updateList;
    },
    filterZoneWiseOption() {
      let projectNamesSet = new Set();
      this.tableData.forEach((item) => {
        projectNamesSet.add(item.groupName);
      });
      let projectNamesArray = Array.from(projectNamesSet);
      return projectNamesArray;
    },
    filterProductWiseOption() {
      let projectNamesSet = new Set();
      this.tableData.forEach((item) => {
        projectNamesSet.add(item.productOptionName);
      });
      let projectNamesArray = Array.from(projectNamesSet);
      return projectNamesArray;
    },
    filterOption() {
      let projectNamesSet = new Set();
      this.tableData.forEach((item) => {
        projectNamesSet.add(item.projectName);
      });
      let projectNamesArray = Array.from(projectNamesSet);
      return projectNamesArray;
    },
    filteredZonewiseTableData() {
      if (this.filterDataProjectSelection.length === 0) {
        return this.tableData;
      }
      return this.tableData.filter((item) =>
        this.filterDataProjectSelection.includes(item.groupName)
      );
    },
    filteredOverAllZonewiseTableData() {
      return this.tableData;
    },
    filteredProductwiseTableData() {
      if (this.filterDataProductSelection.length === 0) {
        return this.tableData;
      }
      return this.tableData.filter((item) =>
        this.filterDataProductSelection.includes(item.productOptionName)
      );
    },
    filteredTableData() {
      if (this.filterDataProjectSelection.length === 0) {
        return this.tableData;
      }
      return this.tableData.filter((item) =>
        this.filterDataProjectSelection.includes(item.projectName)
      );
    },
  },
  created() {
    this.items = this.tableData.concat(
      ...this.items.map((project) => project.dateWiseData)
    );
    this.rows = this.items.length;
  },
  components: {
    BCard,
    BCardTitle,
    BFormSelect,
    vSelect,
    multiChannelSpinner,
    BCol,
    BRow,
    BModal,
    BCardText,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
  },
  props: {
    rangeType:{
      type: String,
    },
    clientName: {
      type: String,
    },
    clientId: {
      type: String,
    },
    reportType: {
      type: String,
    },
    zoneWiseType: {
      type: Object,
    },
    selectBoxType: {
      type: String,
    },
    projectNameSummary: {
      type: String,
    },
    tableData: {
      type: Array,
    },
    type: {
      type: String,
    },
    zoneWiseDataLoading: {
      type: Boolean,
    },
    productWiseDataLoading: {
      type: Boolean,
    },
    businessTypeProject: {
      type: String,
    },
    selectionType: {
      type: String,
    },
    zoneWiseOptionsLoad: {
      type: Number,
    },
    productAllProjectWiseType: {
      type: Array,
    },
    channelType: {
      type: String,
    },
    hideSelectBox: {
      type: String,
    },
    salesforceDataFound:{
      type:Boolean
    }
  },
  mounted() {
    this.handleMetricsChange("all");
    this.handleZoneWiseChange("all");
    this.handleSubOptionsChange("all");
    // this.filterDataZoneWiseTyp = "defaultOne";
    this.filterDataZoneWiseType == "All Zones";
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.heightFixed {
  .vs__dropdown-toggle {
    padding: 0 0 4px 0;
    background: none;
    font-size: 13px !important;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    height: 38px !important;
    margin-bottom: 10px;
  }
}
.selectBox {
  .custom-select {
    width: 100px !important;
  }
}

.custom-vue-select .vs__selected-options {
  display: flex !important;
  overflow-x: auto !important;
  white-space: nowrap;
  flex-wrap: nowrap !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.custom-vue-select .vs__selected {
  display: inline-block;
  margin-right: 5px;
}

.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.tables-google-container {
  display: flex;
  width: 100%;
  overflow-x: auto;

  .table thead th {
    color: black;
    font-size: 12px !important;
    width: 400px !important;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 0.5px solid #ddd;
    color: black;
    text-align: center;
    font-size: 12px;
    height: 50px;
  }

  .dateTd {
    font-size: 12px !important;
    color: black;
  }

  th {
    color: black;
    background-color: #eff5f5 !important;
  }

  .padding0 {
    padding: 0px;
  }
}

.tables-meta-container {
  display: flex;
  width: 100%;
  overflow-x: auto;

  .table thead th {
    // border-bottom: 1px solid #ebe9f1;
    font-size: 12px !important;
    width: 400px !important;
    color: black;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 0.5px solid #ddd;
    color: black;
    text-align: center;
    font-size: 12px;
    height: 60px;
  }

  .dateTd {
    color: black;
    font-size: 12px !important;
  }

  th {
    color: black;
    background-color: #eff5f5 !important;
  }

  .padding0 {
    padding: 0px;
  }
}

.date-column {
  position: sticky;
  left: 0;
  z-index: 1;
  color: black;
  background-color: white;

  .padding0 {
    padding: 0px;
  }

  .paddingMeta0 {
    padding: 0 2px !important;
  }

  td {
    color: black;
    background-color: #ffffff;
  }
}

.dateProjectTd {
  color: black;
  font-size: 12px !important;
}

.dateFacebookProjectTd {
  color: black;
  font-size: 12px !important;
}
.single-select {
  height: 38px !important;
}
</style>
