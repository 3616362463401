<template>
  <div>
    <b-row class="match-height-container">
      <template v-if="this.businessType[0] == 'leadgen'">
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.spendsData" title="Spends" :summary="summaryData.spends" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.impressionsData" title="Impressions" :summary="summaryData.impressions" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.clicksData" title="Clicks" :summary="summaryData.clicks" />
        </b-col>
        <!-- <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.cpmData" title="CPM" :summary="summaryData.cpm" />
        </b-col> -->
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.cpcData" title="CPC" :summary="summaryData.cpc" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.CTRData" title="CTR" :summary="summaryData.ctr" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.conversionAllData" title="Leads" :summary="summaryData.conversions" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.cpcData" title="CPL" :summary="summaryData.cpc" />
        </b-col>
      </template>
      <template v-else-if="this.businessType[0] == 'leadform'">
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.spendsData" title="Spends" :summary="summaryData.spends" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.impressionsData" title="Impressions" :summary="summaryData.impressions" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.clicksData" title="Clicks" :summary="summaryData.clicks" />
        </b-col>
        <!-- <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.cpmData" title="CPM" :summary="summaryData.cpm" />
        </b-col> -->
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.cpcData" title="CPC" :summary="summaryData.cpc" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.CTRData" title="CTR" :summary="summaryData.ctr" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.conversionAllData" title="Leads" :summary="summaryData.conversions" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.cpcData" title="CPL" :summary="summaryData.cpc" />
        </b-col>
      </template>
      <template v-else>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.spendsData" title="Spends" :summary="summaryData.spends" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.impressionsData" title="Impressions" :summary="summaryData.impressions" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.clicksData" title="Clicks" :summary="summaryData.clicks" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.cpcData" title="CPC" :summary="summaryData.cpc" />
        </b-col>
        <b-col cols="3" class="chartContainer" v-if="this.businessType[0] !== 'leadgen' ">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.cpmData" title="CPM" :summary="summaryData.cpm" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.conversionsData" title="CPR" :summary="summaryData.costPerResult" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.CTRData" title="CTR" :summary="summaryData.ctr" />
        </b-col>
        <b-col cols="3" class="chartContainer">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.conversionsValueData" title="Conversion Value"
            :summary="summaryData.conversionValue" />
        </b-col>
       
        <b-col cols="3" class="chartContainer">
          <div v-if="this.businessType == 'ecommerce'">
            <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.webPurchasesAllData" title="Purchases" :summary="summaryData.webPurchases" />
          </div>
          <div v-else>
            <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.conversionAllData" title="Conversions" :summary="summaryData.conversions" />
            </div>
          <!-- <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.conversionsValueData" title="Conversion Value"
            :summary="summaryData.conversionValue" /> -->
        </b-col>
        <b-col cols="3" class="chartContainer" v-if="this.businessType[0] !== 'leadgen' ">
          <faceBookChart v-if="faceBookChartValue.labels.length" :labels="faceBookChartValue.labels"
            :data="faceBookChartValue.roasData" title="ROAS" :summary="summaryData.roas" />
        </b-col>
      </template>
      <!-- <b-col cols="3" class="chartContainer">
        <faceBookChart
          v-if="faceBookChartValue.labels.length"
          :labels="faceBookChartValue.labels"
          :data="faceBookChartValue.conversionAllData"
          title="Sales"
          :summary="summaryData.sales"
        />
      </b-col> -->
    </b-row>
    <!-- <b-row class="match-height-container">    
    </b-row> -->
  </div>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
//   import CardStatisticsGroup from "../GoogleAds/CardStatisticsGroup.vue";
import faceBookChart from "./FacebookCharts";

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    iconCardType: { type: String },
    summaryData: {
      type: Object,
      default: () => {
        return {
          avg_cpc: 0,
          clicks: 0,
          conversionValue: 0,
          conversions: 0,
          costPerResult: 0,
          cpc: 0,
          ctr: 0,
          cvr: 0,
          impressions: 0,
          roas: 0,
          spends: 0,
          cpm:0,
        };
      }
    },
    chartData: {
      type: Array,
      default: () => {
        return [];
      }
    },
    businessType: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      iconType: "Hello"
    };
  },
  computed: {
    faceBookChartValue() {
      let data = this.chartData;
      let labels = [];
      let clicksData = [{ name: "Clicks", data: [] }];
      let spendsData = [{ name: "Spends", data: [] }];
      let roasData = [{ name: "ROAS", data: [] }];
      let conversionAllData = [{ name: "Leads", data: [] }];
      let CTRData = [{ name: "CTR", data: [] }];
      let impressionsData = [{ name: "Impressions", data: [] }];
      let conversionsData = [{ name: "CVR", data: [] }];
      let conversionsValueData = [{ name: "Conversion Value", data: [] }];
      let cpcData = [{ name: "CPC", data: [] }];
      let webPurchasesAllData = [{ name: "Purchases", data: [] }];
      let cpmData = [{ name: "CPM", data: [] }]

      for (let i = 0; i < data.length; i++) {
        const dataObject = data[i];
        labels.push(dataObject.date);
        clicksData[0].data.push(dataObject.data.clicks);
        spendsData[0].data.push(dataObject.data.spends);
        roasData[0].data.push(dataObject.data.roas);
        conversionAllData[0].data.push(dataObject.data.conversions)
        CTRData[0].data.push(dataObject.data.ctr);
        impressionsData[0].data.push(dataObject.data.impressions);
        conversionsData[0].data.push(dataObject.data.costPerResult);
        conversionsValueData[0].data.push(dataObject.data.conversionValue);
        cpcData[0].data.push(dataObject.data.cpc);
        cpmData[0].data.push(dataObject.data.cpm);
        webPurchasesAllData[0].data.push(dataObject.data.webPurchases);
      }
      return {
        labels,
        clicksData,
        spendsData,
        roasData,
        conversionAllData,
        CTRData,
        impressionsData,
        conversionsData,
        conversionsValueData,
        cpcData,
        cpmData,
        webPurchasesAllData
      };
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    //   CardStatisticsGroup,
    faceBookChart
  }
};
</script>
<style>
.chartContainer {
  padding: 6px !important;
}
</style>
